import { Box, Button, Typography, styled } from "@mui/joy";
import Header from "./Header";
import logo from "../assets/logo.png";
import background from "../assets/bg-angle.png";
import phone1 from "../assets/phone1.png";
import { Link } from "react-router-dom";
import { useMemo, useRef } from "react";
import SectionHeader from "./SectionHeader";

const Img = styled("img")(({ theme }) => ({
	width: 400,
	[theme.breakpoints.down("sm")]: {
		width: 200,
	},
}));

export default function Hero(props: any) {
	const isMobile = useMemo(() => window.innerWidth < 768, []);
	const ourMissionRef = useRef(null);

	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					position: "relative",
					width: "100%",
					height: 800,
				}}>
				<Header {...props} aboutRef={ourMissionRef} />
				<img
					src={background}
					style={{
						position: "absolute",
						top: 0,
						zIndex: -1,
						width: "100%",
						height: 800,
						objectFit: isMobile ? "cover" : "initial",
						// clipPath: "polygon(0 0,100% 0,100% calc(100% - 150px),0 100%)",
					}}
					alt="blue map"
				/>
				<Box
					sx={{
						position: "absolute",
						top: "40%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: {
							xs: "90%",
							md: "50%",
						},
						textAlign: "center",
					}}>
					<Typography
						sx={{
							color: "white",
							fontWeight: "600",
							fontSize: {
								xs: 38,
								sm: 45,
							},
							mb: 2,
						}}>
						Creating Community
					</Typography>
					<Typography
						fontSize={18}
						sx={{
							color: "#9BAFBC",
						}}>
						Growing the sport of squash by bringing people together
					</Typography>
					<Link to={"/vu"}>
						<Button
							sx={{
								mt: 3,
								width: 200,
								height: 40,
								backgroundColor: "#E89201",
								":hover": {
									backgroundColor: "#ad6e03",
								},
							}}>
							<Typography
								level="title-md"
								sx={{ color: "#000", fontWeight: "bold" }}>
								SEARCH FOR A CLUB
							</Typography>
						</Button>
					</Link>
				</Box>
			</div>
			<Box
				ref={ourMissionRef}
				sx={{
					px: {
						xs: 1,
						md: 10,
					},
					pt: 7,
					pb: 13,
					display: {
						xs: "block",
						sm: "flex",
					},
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
				}}>
				<SectionHeader title="OUR MISSION" />
				<Typography
					level="body-lg"
					textAlign={"center"}
					width={{
						xs: "100%",
						sm: "70%",
						lg: "50%",
					}}
					mt={2}
					sx={{ color: "#7f7f7f" }}>
					ClubHub is more than just a website; it’s a movement dedicated to
					increasing participation in squash. Everything we do is dedicated to
					our mission and every partner we take on maintains a similar guiding
					principle.
				</Typography>
			</Box>
		</div>
	);
}
