import { Box, Button, Typography } from "@mui/joy";
import logo from "../assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClubQuery } from "../apis/clubhub.api";
import { useGetVenueQuery } from "../apis/squashFinder.api";
import useIsAdmin from "../hooks/useIsAdmin";
import moment from "moment";
import { useMemo } from "react";

// Helper function to map day abbreviations to full names
const dayFullNames: any = {
	Mo: "Monday",
	Tu: "Tuesday",
	We: "Wednesday",
	Th: "Thursday",
	Fr: "Friday",
	Sa: "Saturday",
	Su: "Sunday",
};

// Helper function to get the order of days for grouping
const dayOrder = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

// Function to group consecutive days
const groupDays = (days: any) => {
	const ranges = [];
	let start = 0;

	for (let i = 1; i <= days.length; i++) {
		// Check if the current day is consecutive with the previous day
		if (
			i === days.length ||
			dayOrder.indexOf(days[i]) !== dayOrder.indexOf(days[i - 1]) + 1
		) {
			// Create a range if start and end differ, otherwise add single day
			if (start === i - 1) {
				ranges.push(dayFullNames[days[start]]);
			} else {
				ranges.push(
					`${dayFullNames[days[start]]} - ${dayFullNames[days[i - 1]]}`
				);
			}
			start = i; // Move start to the next group
		}
	}
	return ranges.join(", ");
};

// Render the schedule with grouped day ranges
const renderSchedule = (schedule: any) => {
	console.log(schedule);
	const days = schedule?.days;
	const startTime = moment(schedule.start, "HH:mm").format("HH:mm");
	const endTime = moment(schedule.end, "HH:mm").format("HH:mm");

	const dayRange = groupDays(days);

	return `${dayRange}: ${startTime} to ${endTime}`;
};

interface FooterProps {
	blogRef?: any;
	onAdminClick?: () => void;
	isClubSite?: boolean;
}

export default function Footer({
	blogRef,
	onAdminClick,
	isClubSite = true,
}: FooterProps) {
	const navigate = useNavigate();
	const scrollTo = (ref: any) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	};
	const { venueName } = useParams();

	const isAdmin = useIsAdmin(venueName || "");

	const { data: venueDetails } = useGetVenueQuery(venueName || "", {
		skip: !venueName,
	});

	const { data: clubInfo } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const onHomePress = () => {
		if ("/vu/" + venueName === window.location.pathname) {
			window.scroll({ top: 0, behavior: "smooth" });
		} else {
			navigate("/vu/" + venueName);
			window.scrollTo(0, 0);
		}
	};

	const _onAdminPress = () => {
		if (isAdmin === null) return;

		if (isAdmin) {
			navigate("/vu/" + venueName + "/admin");
			window.scrollTo(0, 0);
		} else onAdminClick?.();
	};

	const handleGalleryClick = () => {
		const galleryUrl = "/vu/" + venueName + "/gallery";
		navigate(galleryUrl);
		window.scrollTo(0, 0);
	};

	const handleCourtBookingClick = () => {
		const courtBookingUrl = "/vu/" + venueName + "/courtbooking";
		navigate(courtBookingUrl);
		window.scrollTo(0, 0);
	};

	const handleBlogClick = () => {
		if (blogRef) {
			scrollTo(blogRef);
		} else {
			const blogUrl = "/vu/" + venueName + "/#news";
			navigate(blogUrl);
		}
	};

	const plan = clubInfo?.plan.planType || "basic";

	const data = useMemo(() => {
		if (isClubSite) {
			return {
				logo:
					plan !== "basic" && clubInfo?.logo
						? "https://storage.googleapis.com/activscout/" + clubInfo?.logo
						: null,
				description: clubInfo?.description,
				operations: clubInfo?.operations,
				physical_address: venueDetails?.physical_address,
				email: venueDetails?.email,
				phone: venueDetails?.phone,
				name: venueDetails?.name,
			};
		} else {
			return {
				logo: logo,
				description: `ClubHub supports today’s top club software providers integrated into
						a single social network to bring together the entire squash
						community like never before.`,
				operations: clubInfo?.operations,
				physical_address:
					"8621 Fissile Lane Whistler, British Columbia Canada  V8E 0G1",
				email: "sales@clubhub.net",
				phone: "1 (604) 902-2978",
				name: "ClubHub Sports Inc.",
			};
		}
	}, [clubInfo, venueDetails]);

	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					height: 10,
					backgroundColor: "#E79301",
				}}
			/>
			<Box
				sx={{
					backgroundColor: "#1f3a6e",
					display: {
						xs: "block",
						md: "flex",
					},
					justifyContent: "space-evenly",
					py: 10,
					px: 10,
				}}>
				<Box
					sx={{
						maxWidth: 300,
						textAlign: "center",
					}}>
					{data.logo && (
						<img
							src={data.logo}
							alt="logo"
							width={90}
							height={90}
							style={{ marginLeft: "auto", marginRight: "auto" }}
						/>
					)}
					<Typography level="h3" sx={{ color: "white", mt: 1 }}>
						{data.name}
					</Typography>
					<Typography sx={{ color: "white", mt: 1 }}>
						{data.description}
					</Typography>
				</Box>
				<Box
					sx={{
						mt: {
							xs: 2,
							md: 0,
						},
					}}>
					<Typography level="h3" sx={{ color: "white" }}>
						{data.name}
					</Typography>
					<Typography sx={{ color: "white", mt: 2, maxWidth: 200 }}>
						{data.physical_address}
					</Typography>
					<Typography sx={{ color: "white", mt: 5 }}>{data.email}</Typography>
					<Typography sx={{ color: "white" }}>{data.phone}</Typography>
				</Box>
				{clubInfo?.operations && (
					<Box
						sx={{
							mt: {
								xs: 2,
								md: 0,
							},
						}}>
						<Typography sx={{ color: "white", fontWeight: "bold", mb: 3 }}>
							OPENING HOUR
						</Typography>
						{clubInfo?.operations?.map((schedule: any) => (
							<Typography sx={{ color: "white", fontWeight: "bold" }}>
								{renderSchedule(schedule)}
							</Typography>
						))}
					</Box>
				)}
				<Box
					sx={{
						mt: {
							xs: 2,
							md: 0,
						},
						display: "flex",
						flexDirection: "column",
						gap: 1,
					}}>
					<Typography level="h3" sx={{ color: "white" }}>
						USEFUL LINKS
					</Typography>
					{venueName ? (
						<>
							<Typography
								onClick={onHomePress}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "}HOME
							</Typography>
							<Typography
								onClick={handleGalleryClick}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "}GALLERY
							</Typography>
							<Typography
								onClick={handleCourtBookingClick}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "} COURT BOOKING
							</Typography>
							<Typography
								onClick={handleBlogClick}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "} BLOG
							</Typography>
							<Typography
								onClick={_onAdminPress}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "} CLUB ADMIN
							</Typography>
						</>
					) : (
						<>
							<Typography
								onClick={handleBlogClick}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "}BLOG
							</Typography>
							<Typography
								onClick={() => navigate("/vu")}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "}COURT FINDER
							</Typography>
							<Typography
								onClick={() =>
									window.open(
										"https://apps.apple.com/in/app/clubhub-social-network/id1642694740"
									)
								}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "} CLUBHUB - APP STORE
							</Typography>
							<Typography
								onClick={() =>
									window.open(
										"https://play.google.com/store/apps/details?id=com.app.activscout"
									)
								}
								sx={{ color: "white", cursor: "pointer" }}>
								{"> "} CLUBHUB - PLAY STORE
							</Typography>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
}
