import { Box, Button, Textarea, Typography } from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import SectionHeader from "../../Components/SectionHeader";
import { ReactComponent as SquashPlayers } from "../../assets/squash-players.svg";
import Footer from "../../Components/Footer";
import HoursOfOperation from "../../Components/HoursOfOperation";
import {
	useGetClubQuery,
	useSaveClubDetailsMutation,
} from "../../apis/clubhub.api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import AddAdministrators from "../../Components/AddAdministrators";

export default function AdminHome() {
	const { venueName } = useParams();
	const { data: club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});
	const [saveClubDetails, { isLoading: isSaving }] =
		useSaveClubDetailsMutation();

	const [image, setImage] = useState<string | null>(null);
	const [selectedImage, setSelectedImage] = useState<File | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [clubId, setClubId] = useState<string | null>(null);
	const [operations, setOperations] = useState<any[]>([]);

	useEffect(() => {
		if (club) {
			setImage("https://storage.googleapis.com/activscout/" + club.logo);
			setDescription(club.description);
			setClubId(club._id);
			setOperations(club.operations);
		}
	}, [club]);

	const handleSubmit = async () => {
		try {
			if (!image || !description) {
				alert("All fields are required");
				return;
			}

			const formData = new FormData();

			if (selectedImage instanceof File) {
				const imageName = nanoid();
				formData.append("images", selectedImage as File, imageName);
				formData.append("logo", imageName);
			}
			formData.append("description", description);

			await saveClubDetails({
				id: clubId || "",
				data: formData,
			}).unwrap();
			alert("Club details saved successfully");
		} catch (error) {
			console.error(error);
			alert("An error occurred while saving club details");
		}
	};

	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: {
						xs: 0,
						sm: 7,
					},
				}}>
				<SectionHeader title="FACILITIES" />
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						py: {
							xs: 5,
							sm: 0,
						},
					}}>
					<Typography
						textAlign="center"
						width={{
							xs: "auto",
							sm: "30%",
						}}>
						To edit the list of venue features a description of the squash
						facilities, download the Squash Players App.
					</Typography>
					<SquashPlayers
						style={{
							width: 200,
							height: 200,
						}}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							width: "30%",
							alignItems: "center",
						}}>
						<Button sx={{ bgcolor: "#d0d0d0", width: 200 }}>
							<Typography>iPhone App Store</Typography>
						</Button>
						<Button sx={{ bgcolor: "#d0d0d0", width: 200 }}>
							<Typography>Android Play Store</Typography>
						</Button>
					</Box>
				</Box>
				<Box sx={{ mt: 10 }}>
					<SectionHeader title="BRANDING" />
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
							py: 5,
							flexDirection: {
								xs: "column",
								sm: "row",
							},
						}}>
						<Box>
							<Typography textAlign={"center"} pb={2}>
								Add your venues logo
							</Typography>
							<Box
								sx={{
									width: 200,
									height: 200,
									bgcolor: "#d0d0d0",
									borderRadius: "50%",
									position: "relative",
								}}>
								<img
									src={image || ""}
									alt="logo"
									style={{ width: 200, height: 200 }}
								/>
								<input
									type="file"
									accept="image/*"
									onChange={(e) => {
										const file = e.target.files?.[0];
										if (file) {
											setSelectedImage(file);
											setImage(URL.createObjectURL(file));
										}
									}}
									style={{
										width: 200,
										height: 200,
										opacity: 0,
										cursor: "pointer",
										borderRadius: "50%",
										position: "absolute",
										left: 0,
									}}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
								mt: {
									xs: 5,
									sm: 0,
								},
							}}>
							<Typography>
								Add a brief description or slogan for your club
							</Typography>
							<Textarea
								sx={{ height: 200 }}
								value={description || ""}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<Button
								loading={isSaving}
								onClick={handleSubmit}
								sx={{ width: 150, ml: "auto" }}>
								Submit
							</Button>
						</Box>
					</Box>
				</Box>
				<AddAdministrators
					admins={club?.admins || []}
					clubId={club?.clubId || ""}
				/>
				<HoursOfOperation clubId={clubId as string} operations={operations} />
			</Box>
			<Footer />
		</div>
	);
}
