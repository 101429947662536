import React from "react";
import herobackground from "../assets/hero1.png";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Input,
	Typography,
	styled,
} from "@mui/joy";
import squashCourtIcon from "../assets/Squash Court.png";
import clubIcon from "../assets/Club.png";
import { isMobile } from "react-device-detect";
import phoneIcon from "../assets/phone.png";
import linkIcon from "../assets/link 1.png";
import emailIcon from "../assets/Mail.png";
import mapIcon from "../assets/Map.png";

const styles = {
	header: {
		width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
	heroBackground: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: -1,
	},
	mainContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "80vh",
		flexDirection: "column",
	},
	contactBox: {
		padding: 1,
		borderWidth: 1,
		borderRadius: "10px",
		borderColor: "black",
		backgroundColor: "#FED93E",
		display: "flex",
		alignItems: "center",
		gap: 1,
	},
	categoryContainer: {
		mt: 2,
		display: "flex",
		gap: 4,
	},
	squashCourtContainer: {
		mt: 15,
		padding: "0 40px",
	},
	squashCourtHeader: {
		borderBottom: 1,
		borderColor: "black",
		display: "flex",
		gap: 2,
		pb: 1,
	},
	squashCourtIcon: {
		width: 35,
	},
	courtBox: {
		width: 130,
		height: 130,
		borderRadius: "50%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	courtText: {
		fontSize: 50,
		fontWeight: "700",
		color: "white",
	},
	courtDescription: {
		mt: 1,
		fontWeight: "700",
		textAlign: "center",
		width: "70%",
	},
	facilityBox: {
		background: "rgba(225, 225, 225, 0.4)",
		padding: 2,
		width: 200,
		borderRadius: "20px",
		display: "flex",
		flexDirection: "column",
		gap: 3,
	},
	priceBox: {
		background: "#BABABA",
		borderRadius: "20px",
		display: "flex",
		flexDirection: "column",
		gap: 2,
		padding: "25px 30px",
	},
	footer: {
		height: 300,
		width: "100%",
		backgroundColor: "#1F3A6E",
	},
};

const FacilityBox = ({ facilities }: any) => (
	<Box
		sx={[
			styles.facilityBox,
			{
				width: {
					sm: 200,
					xs: "100%",
				},
			},
		]}>
		{facilities.map((facility: any, index: number) => (
			<Typography key={index} sx={{ color: "white", fontWeight: "700" }}>
				&#x2022; {facility}
			</Typography>
		))}
	</Box>
);

const PriceBox = ({ prices, style }: any) => (
	<Box
		sx={[
			styles.priceBox,
			{
				width: {
					sm: "auto",
					xs: "100%",
				},
			},
			style,
		]}>
		{prices.map((price: number, index: number) => (
			<Typography
				key={index}
				sx={{ color: "#1F3A6E", fontWeight: "700", fontSize: 25 }}>
				{price}
			</Typography>
		))}
	</Box>
);

const PluginCoverScreen = () => {
	const facilities = ["Changing Rooms", "Showers", "Parking", "Gym"];
	const dropInFees = ["1x Pass", "10x Pass", "30x Pass"];
	const dropInPrices = ["$10.50", "$80", "$200"];
	const membershipDurations = ["1 Month", "3 Months", "6 Months", "1 Year"];
	const membershipPrices = [
		["$50", "$100", "$150", "$200"],
		["$70", "$140", "$210", "$280"],
		["$90", "$180", "$270", "$360"],
	];
	const programs = {
		col1: [
			"Squash League",
			"Mark Revelle Memorial Tournament",
			"Whistler Challenger Tournament",
			"Thirsty Thursday Drop-In",
			"Beginners Clinic",
			"Junior Program",
		],
		col2: ["Gym", "Drink Vending Machine", "Booking App", "First Aid Kit"],
		col3: ["Multi-Sport Court", "Snack Vending Machine", "Premanently Staffed"],
	};

	return (
		<Box>
			<Box style={styles.header}>
				<Box sx={styles.headerText}>
					<img src={require("../assets/Back.png")} style={styles.headerLogo} />
					<Typography sx={{ color: "white" }}> Back to map</Typography>
				</Box>
				<Box sx={styles.headerText}>
					<Typography sx={{ color: "white" }}>Club Admin</Typography>
					<img
						src={require("../assets/logo.png")}
						style={styles.headerTitle}
						alt={""}
					/>
				</Box>
			</Box>
			<Box
				sx={[
					styles.mainContainer,
					{
						height: {
							xs: "30vh",
							sm: "80vh",
						},
					},
				]}>
				<img
					src={herobackground}
					alt="hero"
					style={{
						...(styles.heroBackground as React.CSSProperties),
						height: isMobile ? 400 : "100%",
					}}
				/>

				<Typography
					sx={{
						fontSize: {
							xs: 30,
							sm: 35,
						},
						fontWeight: "700",
						textAlign: "center",
					}}>
					Meadow Park Recreation Centre
				</Typography>
				<Typography sx={{ fontSize: 20 }}>
					8625 BC-99, Whistler BC V8E 1K1, Canada
				</Typography>
				{!isMobile && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Box sx={{ marginTop: 2 }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: 2,
								}}>
								<Box sx={styles.contactBox}>
									<img src={phoneIcon} alt="phone" style={{ width: 20 }} />
									<Typography>+1 (604) 935-7529</Typography>
								</Box>
								<Box sx={styles.contactBox}>
									<img src={linkIcon} alt="phone" style={{ width: 25 }} />
									<Typography>Official Website</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: 2,
									marginTop: 2,
								}}>
								<Box sx={styles.contactBox}>
									<img src={emailIcon} alt="phone" style={{ width: 25 }} />
									<Typography>Email</Typography>
								</Box>
								<Box sx={styles.contactBox}>
									<img src={mapIcon} alt="phone" style={{ width: 25 }} />
									<Typography>Map</Typography>
								</Box>
							</Box>
						</Box>

						<Box sx={styles.categoryContainer}>
							<Typography>Category</Typography>
							<Box>
								<Typography>&#x2022; Leisure Centre</Typography>
								<Typography>&#x2022; Guests Welcome</Typography>
								<Typography>&#x2022; Pay & Play</Typography>
							</Box>
						</Box>
					</Box>
				)}
			</Box>

			{isMobile && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: 2,
					}}>
					<Box sx={{ marginTop: 12 }}>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flexDirection: "column",
							}}>
							<Box sx={styles.contactBox}>
								<img src={phoneIcon} alt="phone" style={{ width: 20 }} />
								<Typography>+1 (604) 935-7529</Typography>
							</Box>
							<Box sx={styles.contactBox}>
								<img src={linkIcon} alt="phone" style={{ width: 25 }} />
								<Typography>Official Website</Typography>
							</Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: 2,
								marginTop: 2,
							}}>
							<Box sx={styles.contactBox}>
								<img src={emailIcon} alt="phone" style={{ width: 25 }} />
								<Typography>Email</Typography>
							</Box>
							<Box sx={styles.contactBox}>
								<img src={mapIcon} alt="phone" style={{ width: 25 }} />
								<Typography>Map</Typography>
							</Box>
						</Box>
					</Box>

					<Box sx={styles.categoryContainer}>
						<Typography>Category</Typography>
						<Box>
							<Typography>&#x2022; Leisure Centre</Typography>
							<Typography>&#x2022; Guests Welcome</Typography>
							<Typography>&#x2022; Pay & Play</Typography>
						</Box>
					</Box>
				</Box>
			)}

			<Box sx={styles.squashCourtContainer}>
				<Box sx={styles.squashCourtHeader}>
					<img
						src={squashCourtIcon}
						alt="squash court"
						style={styles.squashCourtIcon}
					/>
					<Typography>Squash Courts</Typography>
				</Box>

				<Box
					sx={{
						mt: {
							xs: 5,
							sm: 10,
						},
						mb: 5,
						display: "flex",
						justifyContent: "space-around",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						gap: {
							xs: 4,
							sm: 0,
						},
					}}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Box
							sx={{
								...styles.courtBox,
								backgroundColor: "#1F3A6E",
							}}>
							<Typography sx={styles.courtText}>2</Typography>
						</Box>
						<Typography sx={styles.courtDescription}>
							Total Number of Courts
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Box
							sx={{
								...styles.courtBox,
								backgroundColor: "#BABABA",
							}}>
							<Typography sx={styles.courtText}>1</Typography>
						</Box>
						<Typography sx={styles.courtDescription}>
							Glass Backed Courts
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Box
							sx={{
								...styles.courtBox,
								backgroundColor: "#BABABA",
							}}>
							<Typography sx={styles.courtText}>1</Typography>
						</Box>
						<Typography sx={styles.courtDescription}>
							Fully Enclosed Courts
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					backgroundColor: "#1F3A6E",
					width: "100%",
					padding: {
						xs: 0,
						sm: "20px 60px",
					},
				}}>
				<Box
					sx={[
						styles.squashCourtHeader,
						{
							borderBottom: 0.5,
							borderColor: "white",
							padding: {
								xs: "10px 20px",
								sm: 0,
							},
						},
					]}>
					<img src={clubIcon} alt="club icon" style={styles.squashCourtIcon} />
					<Typography sx={{ color: "white", fontWeight: "700" }}>
						Venue Facilities
					</Typography>
				</Box>

				{isMobile ? (
					<Box
						sx={{
							mt: 4,
							ml: 0,
							mr: 0,
							display: "flex",
							justifyContent: "center",
							padding: "0 20px",
							pb: 4,
						}}>
						<FacilityBox facilities={[...facilities, ...facilities]} />
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							gap: 2,
							mt: 4,
						}}>
						<FacilityBox facilities={facilities} />
						<FacilityBox facilities={facilities} />
						<FacilityBox facilities={facilities} />
					</Box>
				)}
			</Box>

			<Box
				sx={{
					padding: "20px 60px",
				}}>
				<Box sx={[styles.squashCourtHeader]}>
					<Typography sx={{ fontWeight: "700" }}>Fees & Memberships</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						gap: 6,
						mt: 4,
						flexDirection: {
							xs: "column",
							sm: "row",
						},
					}}>
					<Typography mt={2} fontWeight={"700"} fontSize={25}>
						Drop-in Fee
					</Typography>

					<PriceBox
						prices={dropInFees}
						style={{
							width: 350,
						}}
					/>
					<PriceBox prices={dropInPrices} />
				</Box>

				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						mt: 5,
						flexDirection: {
							xs: "column",
							sm: "row",
						},
					}}>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "47%",
							},
						}}>
						<Typography mt={2} fontWeight={"700"} fontSize={25}>
							Membership
						</Typography>
						<PriceBox
							prices={membershipDurations}
							style={{
								width: 350,
								marginLeft: "auto",
							}}
						/>
					</Box>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "69%",
							},
						}}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-evenly",
								alignItems: "center",
								gap: 2,
							}}>
							<Typography mt={2} fontWeight={"700"} fontSize={25}>
								Individual
							</Typography>
							<Typography mt={2} fontWeight={"700"} fontSize={25}>
								Couple
							</Typography>
							<Typography mt={2} fontWeight={"700"} fontSize={25}>
								Family
							</Typography>
						</Box>

						<Box
							sx={{
								background: "#BABABA",
								borderRadius: "20px",
								display: "flex",
								gap: 2,
								padding: "25px 30px",
								width: {
									xs: "100%",
									sm: "92%",
								},
								marginLeft: "auto",
								justifyContent: "space-around",
							}}>
							{membershipPrices.map((prices, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: 2,
									}}>
									{prices.map((price, idx) => (
										<Typography
											key={idx}
											sx={{
												color: "#1F3A6E",
												fontWeight: "700",
												fontSize: 25,
											}}>
											{price}
										</Typography>
									))}
								</Box>
							))}
						</Box>
					</Box>
				</Box>
				<Box>
					<Typography mt={2} fontWeight={"700"} fontSize={25}>
						3rd Party Programming
					</Typography>
					<Box
						sx={{
							display: "flex",
							pl: {
								xs: 0,
								sm: "20%",
							},
							justifyContent: "space-around",
							alignItems: "center",
							flexDirection: {
								xs: "column",
								sm: "row",
							},
						}}>
						<Typography mt={2} fontWeight={"700"} fontSize={25}>
							&#x2022; Whistler Squash Club
						</Typography>
						<PriceBox prices={["$87/year"]} />
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					background: "#BABABA",
					width: "100%",
					p: 6,
				}}>
				<Box
					sx={[
						styles.squashCourtHeader,
						{ borderBottom: 0.5, borderColor: "white" },
					]}>
					<img src={clubIcon} alt="club icon" style={styles.squashCourtIcon} />
					<Typography sx={{ color: "#1F3A6E", fontWeight: "700" }}>
						Programs
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-around",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						background: {
							xs: "rgba(225, 225, 225, 0.4)",
							sm: "transparent",
						},
						padding: {
							xs: 4,
							sm: 0,
						},
						borderRadius: {
							xs: "20px",
							sm: "0",
						},
						mt: {
							xs: 4,
							sm: 0,
						},
					}}>
					{Object.keys(programs).map((key, index) =>
						isMobile ? (
							<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
								{(programs as any)[key].map((program: string, idx: number) => (
									<Typography
										key={idx}
										sx={{ color: "#1F3A6E", fontWeight: "700" }}>
										&#x2022; {program}
									</Typography>
								))}
							</Box>
						) : (
							<Box
								key={index}
								sx={{
									display: "flex",
									justifyContent: "space-around",
									gap: 2,
									mt: 4,
									background:
										index === 0 ? "rgba(225, 225, 225, 0.4)" : "transparent",
									padding: 4,
									borderRadius: "20px",
								}}>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
									{(programs as any)[key].map(
										(program: string, idx: number) => (
											<Typography
												key={idx}
												sx={{ color: "#1F3A6E", fontWeight: "700" }}>
												&#x2022; {program}
											</Typography>
										)
									)}
								</Box>
							</Box>
						)
					)}
				</Box>
			</Box>
			<div style={styles.footer}></div>
		</Box>
	);
};

export default PluginCoverScreen;
