import { Box, Modal, ModalClose } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";

const ImageViewer = ({ src, type }: { src: string; type?: string }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (src) setOpen(true);
	}, [src]);

	const isMobile = useMemo(() => window.innerWidth < 768, []);

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}>
				<ModalClose
					sx={{
						background: "white",
					}}
				/>
				{type === "video/mp4" ? (
					<video
						src={src}
						controls
						style={{
							width: isMobile ? "100%" : "50%",
							height: "auto",
						}}
					/>
				) : (
					<img
						src={src}
						alt="image"
						style={{
							width: isMobile ? "100%" : "50%",
						}}
					/>
				)}
			</Box>
		</Modal>
	);
};

export default ImageViewer;
