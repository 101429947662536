import React, { useState, useRef, useMemo, FC } from "react";
import JoditEditor from "jodit-react";

interface TextEditorProps {
	placeholder?: string;
	value: string;
	onChange: (newValue: string) => void;
}

const TextEditor: FC<TextEditorProps> = ({ placeholder, value, onChange }) => {
	const editor = useRef(null);

	const config = useMemo(
		() => ({
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: placeholder || "Start typings...",
			buttons: [
				"bold",
				"italic",
				"|",
				"ul",
				"ol",
				"|",
				"paragraph",
				"font",
				"fontsize",
				"brush",
				"|",
				"image",
				"video",
				"table",
				"link",
				"|",
				"left",
				"center",
				"right",
				"justify",
				"|",
				"undo",
				"redo",
				"|",
				"hr",
				"eraser",
				"source",
			],
		}),
		[placeholder]
	);

	return (
		<JoditEditor
			ref={editor}
			value={value}
			config={config}
			// tabIndex={1} // tabIndex of textarea
			onBlur={onChange} // preferred to use only this option to update the content for performance reasons
			onChange={(newContent) => {}}
		/>
	);
};

export default TextEditor;
