import { useParams } from "react-router-dom";
import {
	useGetClubQuery,
	useGetBoxLeaguesQuery,
	useGetLeaguesQuery,
} from "../apis/clubhub.api";

const useLeagues = () => {
	const venueName = (useParams().venueName as string | undefined) || "";

	const { data: club } = useGetClubQuery(venueName, {
		skip: !venueName,
	});

	const { data: leagues = [], isLoading } = useGetLeaguesQuery(
		{
			clubId: club?.clubCode || "",
		},
		{
			skip: !club?.clubCode,
		}
	);

	return { leagues, isLoading };
};

export default useLeagues;
