import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Input,
	Radio,
	Typography,
} from "@mui/joy";
import { ReactComponent as ArrowIcon } from "../../Icons/arrow.svg";
import Footer from "../../Components/Footer";
import AdminHeader from "../../Components/AdminHeader";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetClubQuery } from "../../apis/clubhub.api";
import useTournaments from "../../hooks/useTournaments";
import {
	useGetClublockerRegisteredPlayersQuery,
	useGetTournamentCheckInPlayersQuery,
	useGetTournamentPlayersQuery,
} from "../../apis/court.api";
import { useCreateChatroomMutation } from "../../apis/chatroom.api";

const styles: {
	[key: string]: React.CSSProperties;
} = {
	header: {
		width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
	main: {
		height: 325,
		width: "100%",
		backgroundColor: "#9BAFBC",
		padding: "20px 60px",
		fontSize: 20,
		display: "flex",
		flexDirection: "column",
	},
	mainTitle: {
		fontSize: 40,
		fontWeight: "700",
	},
	mainAddress: {
		fontSize: 20,
	},
	content: {
		padding: "0 40px",
		height: "70vh",
	},
	textItem: {
		padding: "35px 0",
		borderBottom: "1px solid #9BAFBC",
		fontSize: 18,
		fontWeight: "700",
		color: "black",
	},
	footer: {
		height: 300,
		width: "100%",
		backgroundColor: "#1F3A6E",
	},
};

const players = [
	"Ben Thomas",
	"John Doe",
	"Jane Doe",
	"Ken Lelek",
	"Doug Wilson",
	"John Wilson",
	"Charles Stenner",
	"Jane Smith",
	"Kevin Jernigan",
	"James Smith",
	"John Smith",
	"Daryl Simpson",
];

const ProgramingScreen = () => {
	const [expandedTournament, setExpandedTournament] = useState<number>(0);
	const [selectedType, setSelectedType] = useState<string>("");

	const { tournaments, isLoading: isFetchingTournaments } = useTournaments();

	const { data: tournamentPlayers, isFetching: isFetchingPlayers } =
		useGetTournamentPlayersQuery(expandedTournament, {
			skip: !expandedTournament,
		});

	const playersUserIds = useMemo(
		() => tournamentPlayers?.map((player) => player.PlayerId),
		[tournamentPlayers]
	);

	const { data: registeredPlayers, isFetching: isFetchingRegisteredPlayers } =
		useGetClublockerRegisteredPlayersQuery(playersUserIds || [], {
			skip: !playersUserIds,
		});

	const { data: checkedInPlayers, isLoading: checkInPlayersLoading } =
		useGetTournamentCheckInPlayersQuery(expandedTournament, {
			skip: !expandedTournament,
		});

	const [createChatroom, { isLoading: isCreatingGroup }] =
		useCreateChatroomMutation();

	const createChatGroup = async () => {
		try {
			const tournament = tournaments?.find(
				(tournament) => tournament.TournamentID === expandedTournament
			);
			let playerIds: string[] = [];

			if (selectedType === "interested") {
				playerIds =
					registeredPlayers?.map((player) => player._id as string) || [];
			} else if (selectedType === "checked-in") {
				const checkedInClublockerIds =
					checkedInPlayers?.map((player) => player.user.clubLockerUserId) || [];
				playerIds =
					registeredPlayers
						?.filter((player) =>
							checkedInClublockerIds.includes(
								player.clubLockerUserIds as string
							)
						)
						.map((player) => player._id as string) || [];
			} else if (selectedType === "not-checked-in") {
				const checkedInClublockerIds =
					checkedInPlayers?.map((player) => player.user.clubLockerUserId) || [];
				playerIds =
					registeredPlayers
						?.filter(
							(player) =>
								!checkedInClublockerIds.includes(
									player.clubLockerUserIds as string
								)
						)
						.map((player) => player._id as string) || [];
			}

			if (playerIds.length === 0) {
				return alert("No players found for the selected type");
			}

			const suffix =
				selectedType === "interested"
					? " - Interested"
					: selectedType === "checked-in"
					? " - Checked In"
					: " - Not Checked In";

			await createChatroom({
				tournamentId: expandedTournament,
				rname: (tournament?.TournamentName || "") + suffix,
				rmembers: playerIds,
				priv: false,
			}).unwrap();
			alert("Chat group created successfully");
		} catch (error) {
			alert("Failed to create chat group");
		}
	};

	const checkedInPlayersIds = useMemo(
		() => checkedInPlayers?.map((player) => player.user.clubLockerUserId),
		[checkedInPlayers]
	);

	const isLoadingPlayers =
		isFetchingPlayers || isFetchingRegisteredPlayers || checkInPlayersLoading;

	const tournamentPlayerByDivision = useMemo(() => {
		const playersByDivision: Record<string, any[]> = {};

		tournamentPlayers?.forEach((player) => {
			if (!playersByDivision[player.Division]) {
				playersByDivision[player.Division] = [];
			}

			playersByDivision[player.Division].push(player);
		});

		return playersByDivision;
	}, [tournamentPlayers]);

	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: 5,
				}}>
				<Box
					sx={{
						gap: 2,
						display: "flex",
						flexDirection: "column",
					}}>
					<Typography>
						1. To verify your connection to the “Meadow Park Recreation Centre”
						Squash program, open up another browser window and connect to your
						ClubLockr.com account. Copy and paste your venues web address below.
					</Typography>
					<Input
						placeholder="https://"
						variant="plain"
						sx={{
							width: {
								xs: "auto",
								md: "50%",
							},

							background: "#d0d0d0d0",
						}}
					/>
					<Typography>
						2. Secondly, in the box below enter the name of the club management
						software you use as your squash court bookingapplication.
					</Typography>
					<Input
						variant="plain"
						sx={{
							width: {
								xs: "auto",
								md: "50%",
							},
							background: "#d0d0d0d0",
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						marginTop: 5,
					}}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "black",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: 50,
						}}>
						<Typography level="h3">PROGRAMMING</Typography>
					</Box>

					<Typography>
						If you are running any programs through Club Locker, they will be
						listed below. These programs will also be listed on your home page.
						If you do not wish to list one or more of these programs, unselect
						them.
					</Typography>
					<Typography>
						Download the “ClubHub” app today. This app was designed to work with
						Club Locker to improve the player experience and increase
						participation.
					</Typography>
					<Input
						variant="plain"
						sx={{
							width: {
								xs: "auto",
								md: "50%",
							},
							background: "#d0d0d0d0",
						}}
					/>

					{isFetchingTournaments && (
						<CircularProgress
							sx={{
								mx: "auto",
							}}
						/>
					)}
					{tournaments?.map((tournament) => (
						<Accordion
							variant="plain"
							expanded={expandedTournament === tournament.TournamentID}
							onChange={() =>
								setExpandedTournament(
									expandedTournament === tournament.TournamentID
										? 0
										: tournament.TournamentID
								)
							}
							sx={{
								borderBottom: 1,
								borderColor: "#1f3a6e",
								padding: "10px 0px",
							}}>
							<AccordionSummary indicator={<ArrowIcon width={20} />}>
								<Typography level="h3">
									{tournament.TournamentName} -{" "}
									{dayjs(tournament.StartDate).format("dddd MMMM DD")}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{isLoadingPlayers ? (
									<CircularProgress
										sx={{
											mx: "auto",
											py: 5,
										}}
									/>
								) : (
									<Box
										sx={{
											paddingBottom: 5,
											display: "flex",
											flexDirection: "column",
										}}>
										<Typography>
											Improve communication, promotion and a sense of community
											while reducing no-shows through the CLUBHUB app.
										</Typography>
										<Box display={"flex"} alignItems="center" mt={2}>
											<Box textAlign="right" width={"70%"}>
												<Typography>
													Create a social group chat for anyone interested in
													the event:
												</Typography>
												<Typography>
													Create a group chat for the athletes who have
													checked-in to the event:
												</Typography>
												<Typography>
													Send private messages to only those athletes who have
													entered but not yet checked in:
												</Typography>
											</Box>
											<Box
												width={"30%"}
												display={"flex"}
												flexDirection={"column"}>
												<Box onClick={() => setSelectedType("interested")}>
													<Radio
														checked={selectedType === "interested"}
														sx={{
															ml: "30%",
														}}
													/>
												</Box>
												<Box onClick={() => setSelectedType("checked-in")}>
													<Radio
														checked={selectedType === "checked-in"}
														sx={{
															ml: "20%",
														}}
													/>
												</Box>
												<Box onClick={() => setSelectedType("not-checked-in")}>
													<Radio
														checked={selectedType === "not-checked-in"}
														sx={{
															ml: "10%",
														}}
													/>
												</Box>
											</Box>
										</Box>
										{Object.keys(tournamentPlayerByDivision).map((division) => (
											<Accordion variant="plain" sx={{ mt: 2 }}>
												<AccordionSummary indicator={<ArrowIcon width={20} />}>
													<Typography level="h4">{division}</Typography>
												</AccordionSummary>
												<AccordionDetails>
													{tournamentPlayerByDivision[division].map(
														(player) => (
															<Box display={"flex"} alignItems="center" py={1}>
																<Typography width={"70%"} pl={3}>
																	{player.PlayerName}
																</Typography>
																<Box
																	width={"30%"}
																	display={"flex"}
																	sx={{
																		ml: "10%",
																	}}
																	gap={"8%"}>
																	<Radio
																		checked={
																			selectedType === "not-checked-in" &&
																			!checkedInPlayersIds?.includes(
																				player.PlayerId
																			)
																		}
																		sx={
																			{
																				// ml: "10%",
																			}
																		}
																	/>
																	<Radio
																		checked={
																			selectedType === "checked-in" &&
																			checkedInPlayersIds?.includes(
																				player.PlayerId
																			)
																		}
																		sx={
																			{
																				// ml: "20%",
																			}
																		}
																	/>
																	<Radio
																		checked={selectedType === "interested"}
																		sx={
																			{
																				// ml: "10%",
																			}
																		}
																	/>
																</Box>
															</Box>
														)
													)}
												</AccordionDetails>
											</Accordion>
										))}
										<Button
											disabled={isCreatingGroup}
											loading={isCreatingGroup}
											onClick={createChatGroup}
											sx={{
												width: 150,
												p: 0,
												ml: "auto",
												mr: "230px",
												mt: 4,
											}}>
											Create Chat Group
										</Button>
									</Box>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			</Box>
			<Footer />
		</div>
	);
};

export default ProgramingScreen;
