// src/api/courtApi.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";
import {
	Court,
	Player,
	Reservation,
	TournamentPlayer,
} from "../types/clublocker.types";
import moment from "moment";
import transformClubhubResonse from "../utils/transformClubhubResponse";

export const courtApi = createApi({
	reducerPath: "courtApi",
	baseQuery,
	endpoints: (builder) => ({
		getCourts: builder.query<Court[], string>({
			query: (clubId) => `/clublocker-api/getCourts/?clubCode=${clubId}`,
		}),
		getReservations: builder.query<Reservation[], string>({
			query: (clubId) =>
				`/clublocker-api/getReservations/?clubCode=${clubId}&date=${moment().format(
					"YYYY-MM-DD"
				)}`,
		}),
		getBoxLeagues: builder.query<any, string>({
			query: (clubId) => `/clublocker-api/getBoxLeagueByClub/?clubId=${clubId}`,
		}),
		getLeagues: builder.query<any, { clubId: string; includePast?: boolean }>({
			query: ({ clubId, includePast }) =>
				`/clublocker-api/getLeagues/?clubCode=${clubId}&past=${includePast}`,
		}),
		getTournamentPlayers: builder.query<TournamentPlayer[], string | number>({
			query: (id) => `/clublocker-api/getTournamentPlayers/?tournamentId=${id}`,
		}),
		getClublockerRegisteredPlayers: builder.query<any[], number[]>({
			query: (userIds) =>
				`/clublocker-api/getRegisteredUsers/?users=${encodeURIComponent(
					JSON.stringify(userIds)
				)}`,
			transformResponse: transformClubhubResonse,
		}),
		getTournamentCheckInPlayers: builder.query<any[], string | number>({
			query: (tournamentId) =>
				`/tournament-checkin/?tournamentId=${tournamentId}&$limit=100`,
			transformResponse: transformClubhubResonse,
		}),
	}),
});

export const {
	useGetCourtsQuery,
	useGetReservationsQuery,
	useGetBoxLeaguesQuery,
	useGetLeaguesQuery,
	useGetTournamentPlayersQuery,
	useGetClublockerRegisteredPlayersQuery,
	useGetTournamentCheckInPlayersQuery,
} = courtApi;
