import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const url = "https://backend.clubhub.net";
// const url = "http://localhost:8080";

export const baseQuery = fetchBaseQuery({
	baseUrl: url,
	headers: new Headers({
		"Content-Type": "application/json",
		Authorization: `Bearer ${localStorage.getItem("clubhub_token")}`,
	}),
});
