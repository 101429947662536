// src/api/feesApi.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";
import { FeesData } from "../types/clubhubApis.types";

export const feesApi = createApi({
	reducerPath: "feesApi",
	baseQuery,
	endpoints: (builder) => ({
		getFeesData: builder.query<any, string>({
			query: (clubId) => `/club-fees?clubId=${clubId}`,
			transformResponse: (response: any) => response.data,
		}),
		saveClubFees: builder.mutation<any, FeesData>({
			query: (feesData) => ({
				url: "/club-fees",
				method: "POST",
				body: feesData,
			}),
		}),
		updateClubFees: builder.mutation<any, FeesData>({
			query: (feesData) => ({
				url: `/club-fees/${feesData._id}`,
				method: "PATCH",
				body: feesData,
			}),
		}),
	}),
});

export const {
	useGetFeesDataQuery,
	useSaveClubFeesMutation,
	useUpdateClubFeesMutation,
} = feesApi;
