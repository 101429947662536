import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import React, { useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import ImageViewer from "./ImageViewer";
import styled from "@emotion/styled";

interface ImageCarouselProps {
	data: {
		image?: string;
		asset?: string;
		title?: string;
	}[];
	carouselProps?: any;
	imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const ArrowButton = styled(Box)`
	background-color: #a40e4c;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	:hover {
		background-color: rgba(164, 14, 76, 0.8);
	}
`;

const ImageCarousel: React.FC<ImageCarouselProps> = ({
	data,
	carouselProps,
	imageProps,
}) => {
	const isMobile = useMemo(() => window.innerWidth < 768, []);
	const isTablet = useMemo(() => window.innerWidth < 1024, []);
	const slidesToScroll = isMobile ? 1 : 4;
	const sliderRef = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [selectedImage, setSelectedImage] = useState("");

	const slidePrev = () => {
		if (activeIndex - slidesToScroll < data.length) return;
		//@ts-ignore
		sliderRef.current.slickGoTo(activeIndex - slidesToScroll);
		setActiveIndex(activeIndex - slidesToScroll);
	};
	const slideNext = () => {
		if (activeIndex + slidesToScroll >= data.length) return;
		//@ts-ignore
		sliderRef.current.slickGoTo(activeIndex + slidesToScroll);
		setActiveIndex(activeIndex + slidesToScroll);
	};

	const items = useMemo(
		() =>
			data.map((image) => {
				const img = image.asset
					? "https://storage.googleapis.com/activscout/" + image.asset
					: image.image;
				return (
					<Box
						sx={{
							px: 2,
						}}>
						<Box
							sx={{
								textAlign: "center",
								width: 310,
								marginLeft: "auto",
								marginRight: "auto",
							}}>
							<Box
								onClick={() => setSelectedImage(img as string)}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: 300,
									width: 310,
									borderRadius: 10,
								}}>
								<img
									src={img}
									alt="logo"
									height={"75%"}
									style={{
										objectFit: "cover",
									}}
									{...{
										...imageProps,
									}}
								/>
							</Box>
							{image.title && (
								<Typography level="h4" mt={2}>
									{image.title}
								</Typography>
							)}
						</Box>
					</Box>
				);
			}),
		[data]
	);

	const ButtonLeft = (
		<IconButton
			onClick={slidePrev}
			variant="solid"
			size="lg"
			// disabled={activeIndex === 0}
			sx={{
				display: {
					xs: "none",
					md: "block",
				},
				mr: 2,
				backgroundColor: "#a40e4c",
			}}>
			<ChevronLeft />
		</IconButton>
	);

	return (
		<Box
			sx={{
				// display: "flex",
				// justifyContent: "space-around",
				// alignItems: "center",
				px: {
					// xs: "calc(100vw - 85%)",
					md: 5,
				},
			}}>
			<Box sx={{}}>
				<Slider
					onSwipe={(sd: string) => {
						if (sd === "left") {
							setActiveIndex(activeIndex + 1);
						} else {
							setActiveIndex(activeIndex - 1);
						}
					}}
					ref={sliderRef}
					slidesToShow={isMobile ? 1 : isTablet ? 2 : 3}
					slidesToScroll={isMobile ? 1 : isTablet ? 2 : 3}
					arrows={!isMobile && !isTablet}
					dots={false}
					infinite={true}
					speed={500}
					prevArrow={
						<ArrowButton>
							<ChevronLeft sx={{ color: "white" }} />
						</ArrowButton>
					}
					nextArrow={
						<ArrowButton>
							<ChevronRight sx={{ color: "white" }} />
						</ArrowButton>
					}
					{...{
						...carouselProps,
					}}>
					{items}
				</Slider>
			</Box>

			<ImageViewer src={selectedImage} />
		</Box>
	);
};

export default ImageCarousel;
