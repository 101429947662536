import { Box, Button, Typography } from "@mui/joy";
import { FC } from "react";
import { useStartFreeTrialMutation } from "../apis/plan.api";
import { useParams } from "react-router-dom";
import { useGetClubQuery } from "../apis/clubhub.api";

type PlanType = "listing" | "basic" | "premium";

interface PricingCardProps {
	type: PlanType;
}
const features = {
	listing: {
		pricing: "FREE",
		features: ["Claim your listing", "Control all information", "Superior SEO"],
	},
	basic: {
		pricing: "10",
		features: [
			"Everything in Free Plan+",
			"Photo Gallery",
			"Contact Form",
			"Link to your website",
		],
	},
	premium: {
		pricing: "25",
		features: ["Customization", "Promote Programs", "Sell Ads"],
	},
};

const color = {
	listing: "#C07231",
	basic: "#9BAFBC",
	premium: "#E89201",
};
const PricingCard: FC<PricingCardProps> = ({ type }) => {
	const { venueName } = useParams<{ venueName: string }>();
	const { data: club, refetch: refetchClub } = useGetClubQuery(venueName || "");
	const [startFreeTrail] = useStartFreeTrialMutation();

	const onSignUpPress = async () => {
		try {
			await startFreeTrail({ clubId: club?._id, planName: type }).unwrap();
			alert("Your free trial has started");
			refetchClub();
		} catch (e: any) {
			alert(e.data?.message);
			console.error(e);
		}
	};

	return (
		<Box
			sx={{
				width: 280,
				padding: 2,
			}}>
			<Box
				sx={{
					height: 60,
					backgroundColor: color[type],
					color: "white",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: 28,
				}}>
				<h1>{type.toUpperCase()}</h1>
			</Box>
			<Box
				sx={{
					height: 70,
					backgroundColor: "#F6F6F6",
					color: "black",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: 28,
				}}>
				{features[type].pricing !== "FREE" && (
					<Typography fontSize={20} mb={2}>
						$
					</Typography>
				)}
				<Typography fontSize={28} level="h2" fontWeight={"600"}>
					{features[type].pricing}
					{features[type].pricing !== "FREE" && (
						<Typography fontSize={20} fontWeight={"400"}>
							/MON
						</Typography>
					)}
				</Typography>
			</Box>
			<Box
				sx={{
					backgroundColor: color[type],
					padding: 2,
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}>
				{features[type].features.map((feature) => (
					<Typography sx={{ color: "white", fontSize: 18 }}>
						{feature}
					</Typography>
				))}

				<Button
					onClick={onSignUpPress}
					sx={{
						mt: 2,
						width: 150,
						backgroundColor: "#F6F6F6",
						color: "#000",
						"&:hover": {
							backgroundColor: color[type],
							color: "#fff",
						},
						borderRadius: 0,
						alignSelf: "center",
						fontSize: 18,
						fontWeight: 700,
					}}>
					Sign up Now
				</Button>
			</Box>
		</Box>
	);
};

export default PricingCard;
