//@ts-expect-error - no type definitions available for squash-search
import SquashSearch from "squash-search";

export default function FindCourts() {
	return (
		<div>
			<SquashSearch />
		</div>
	);
}
