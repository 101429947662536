import {
	Box,
	Button,
	Tab,
	TabList,
	Tabs,
	Typography,
	IconButton,
	Modal,
	ModalDialog,
} from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import Footer from "../../Components/Footer";
import { useState } from "react";
import clubHubApi, {
	useCreateBlogPostMutation,
	useGetBlogPostsQuery,
	useRemoveBlogPostMutation,
	useUpdateBlogPostMutation,
} from "../../apis/clubhub.api";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { BlogPost } from "../../types/clubhubApis.types";
import BlogEditor from "../../Components/BlogEditor";
import {
	Close,
	DeleteOutline,
	DragHandle,
	DragIndicator,
} from "@mui/icons-material";
//@ts-ignore
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

const Blog = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
	const [createBlogPost, { isLoading }] = useCreateBlogPostMutation();
	const [updateBlogPost] = useUpdateBlogPostMutation();
	const venueName = useParams<{ venueName: string }>().venueName as string;

	const dispatch = useDispatch();

	// Fetch blog posts with RTK Query and get access to the cache update function
	const { data: blogPosts, refetch: refetchBlogs } =
		useGetBlogPostsQuery(venueName);
	const [deletePost, { isLoading: isDeleting }] = useRemoveBlogPostMutation();
	const [updateAuthorDescription, { isLoading: authorDescrUpdateLoading }] =
		clubHubApi.useUpdateAuthorDescriptionMutation();

	// Create or update a blog post
	const handleSubmit = async (
		formData: FormData,
		authorDescription: string | null
	) => {
		if (editingPost) {
			await updateBlogPost({ post: formData, id: editingPost._id });
		} else {
			await createBlogPost(formData);
		}

		if (authorDescription) {
			await updateAuthorDescription(authorDescription);
		}

		alert("Blog post created/updated successfully");
		setEditingPost(null);
		await refetchBlogs();
	};

	// Delete a blog post
	const handleDelete = async (id: string) => {
		try {
			const sure = window.confirm(
				"Are you sure you want to delete this blog post?"
			);
			if (!sure) return;
			await deletePost(id).unwrap();
			alert("Blog post deleted successfully");
			await refetchBlogs();
		} catch (e) {
			alert("Failed to delete blog post");
		}
	};

	// Handle drag end to reorder posts in the RTK Query cache
	const onDragEnd = async (result: any) => {
		const { source, destination } = result;
		if (!destination || !blogPosts) return;

		// Modify the cached data directly to reorder the blog posts

		dispatch(
			clubHubApi.util.updateQueryData(
				"getBlogPosts",
				venueName as string,
				(draftPosts: any) => {
					const [movedPost] = draftPosts.data.splice(source.index, 1);
					draftPosts.data.splice(destination.index, 0, movedPost);
				}
			)
		);

		const swappedPost1Data = new FormData();
		swappedPost1Data.append("position", destination.index.toString());

		const swappedPost2Data = new FormData();
		swappedPost2Data.append("position", source.index.toString());

		await updateBlogPost({
			post: {
				position: blogPosts.data[destination.index].position,
			} as any,
			id: blogPosts.data[source.index]._id,
		});

		await updateBlogPost({
			post: {
				position: blogPosts.data[source.index].position,
			} as any,
			id: blogPosts.data[destination.index]._id,
		});
	};

	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: { xs: 2, sm: 7 },
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}>
				<Tabs
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index as number)}>
					<TabList>
						<Tab>All Posts</Tab>
						<Tab>New Post</Tab>
					</TabList>
				</Tabs>

				{tabIndex === 0 && blogPosts?.data && (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="blogPostList">
							{(provided: any) => (
								<Box {...provided.droppableProps} ref={provided.innerRef}>
									{blogPosts.data.map((post, index) => (
										<Draggable
											key={post._id}
											draggableId={post._id}
											index={index}>
											{(provided: any) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													sx={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														p: 1,
														border: "1px solid #ddd",
														borderRadius: "8px",
														mb: 1,
														bgcolor: "background.paper",
														cursor: "pointer",
													}}>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															gap: 1,
														}}>
														<DragIndicator />
														<Typography>{post.title}</Typography>
													</Box>

													<Box>
														<IconButton
															onClick={() => setEditingPost(post)}
															sx={{ mr: 1 }}>
															<EditIcon />
														</IconButton>
														<IconButton onClick={() => handleDelete(post._id)}>
															<DeleteOutline />
														</IconButton>
													</Box>
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Box>
							)}
						</Droppable>
					</DragDropContext>
				)}

				{tabIndex === 1 && (
					<BlogEditor onSubmit={handleSubmit} isLoading={isLoading} />
				)}

				<Modal open={!!editingPost} onClose={() => setEditingPost(null)}>
					<ModalDialog layout="fullscreen">
						<Box
							sx={{
								width: "100%",
								p: 5,
								borderRadius: 2,
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								height: "100vh",
								overflowY: "auto",
							}}>
							<IconButton
								sx={{
									position: "absolute",
									top: 10,
									right: 10,
									color: "white",
								}}
								onClick={() => setEditingPost(null)}>
								<Close />
							</IconButton>
							<BlogEditor
								post={editingPost || undefined}
								onSubmit={handleSubmit}
								isLoading={isLoading || authorDescrUpdateLoading}
							/>
						</Box>
					</ModalDialog>
				</Modal>
			</Box>
			<Footer />
		</div>
	);
};

export default Blog;
