import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";

const useAuth = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(true);

	useEffect(() => {
		const accessToken = localStorage.getItem("clublocker_token");
		if (accessToken) {
			setIsLoggedIn(true);
			redirect("/vu/:clubname/dashboard");
		}
	}, []);

	return { isLoggedIn };
};

export default useAuth;
