import { Box, Typography } from "@mui/joy";
import { ReactComponent as StarIcon } from "../assets/Star 2.svg";

const planDetails = {
	star: [
		{
			name: "Control over your own data",
			free: true,
			premium: true,
			basic: true,
		},
	],
	"Physical Description": [
		{
			name: "Venue Name",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Address, Region and Country",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Google Maps Link",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Phone Number",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Email Address",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Facility Category",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Membership Requirement?",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Guests Welcome?",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Pay to Play?",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Number and Type of Courts",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Additional Features",
			free: true,
			premium: true,
			basic: true,
		},
	],
	Grow: [
		{
			name: "Inclusion in the Squash Global Map",
			free: true,
			premium: true,
			basic: true,
		},
		{
			name: "Search Engine Optimization",
			free: true,
			premium: true,
			basic: true,
		},
	],
	"Basic Customization": [
		{
			name: "Club Description",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Branding - Add Venue or Club Logo",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Add up to 10 Venue Photos / year",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Add social media links",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Signature photo to match court types",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Define hours of operation",
			free: false,
			premium: true,
			basic: true,
		},
		{
			name: "Contact Form",
			free: false,
			premium: true,
			basic: true,
		},
	],
	"Full Customization": [
		{
			name: "Video embedding",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "40 photos and videos / year",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Invite team members to edit",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Colour customization to reflect brand",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Verification badge for site credibility",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Upcoming event and offer promotion",
			free: false,
			premium: true,
			basic: false,
		},
	],
	"Promotional Tools": [
		{
			name: "Call to action buttons “Register Now”",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Individual player login",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Existing court booking integration",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "No third party advertising",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Share button",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "You can sell ad space to pay for plan",
			free: false,
			premium: true,
			basic: false,
		},
	],
	Assist: [
		{
			name: "Advanced analytics",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Downloadable reports",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Priority customer service",
			free: false,
			premium: true,
			basic: false,
		},
		{
			name: "Onboarding assistance",
			free: false,
			premium: true,
			basic: false,
		},
	],
};

// Reusable styles for repeated Box components
const cellStyle = {
	width: 50,
	textAlign: "center",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderBottom: "1px solid #D0D0D0",
	borderRight: "1px solid #D0D0D0",
	height: 41,
};

const planColors: any = {
	free: "#C07231",
	basic: "#9BAFBC",
	premium: "#E89201",
};

const planDetailsColors: any = {
	star: planColors.free,
	"Physical Description": planColors.free,
	Grow: planColors.free,
	"Basic Customization": planColors.basic,
	"Full Customization": planColors.premium,
	"Promotional Tools": planColors.premium,
	Assist: planColors.premium,
};

// PlanHeader component
const PlanHeader = () => (
	<Box sx={{ display: "flex", alignItems: "center" }}>
		<Box sx={{ width: 350, background: "#D0D0D0", padding: 1, pl: 3 }}>
			<Typography>Plan Comparison</Typography>
		</Box>
		{["free", "basic", "premium"].map((tier) => (
			<Box
				key={tier}
				sx={{
					...cellStyle,
					height: 40,
					background: planColors[tier],
				}}>
				<StarIcon width={20} fill="white" />
			</Box>
		))}
	</Box>
);

// PlanRow component for each plan feature
const PlanRow = ({ detail }: any) => (
	<Box sx={{ display: "flex", alignItems: "center", height: 40 }}>
		<Box
			sx={{
				width: 310,
				padding: 1,
				pl: 3,
				borderBottom: "1px solid #D0D0D0",
				borderRight: "1px solid #D0D0D0",
			}}>
			<Typography>{detail.name}</Typography>
		</Box>
		{["free", "basic", "premium"].map((tier) => (
			<Box key={tier} sx={{ ...cellStyle }}>
				{detail[tier] && <StarIcon fill={planColors[tier]} width={20} />}
			</Box>
		))}
	</Box>
);

const PricingPlanComparison = () => (
	<Box
		sx={{
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			mt: 10,
			overflowY: {
				xs: "scroll",
				sm: "hidden",
			},
		}}>
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}>
			<PlanHeader />

			<Box>
				{Object.entries(planDetails).map(([category, details]) => (
					<Box sx={{ display: "flex", width: 300 }}>
						<Box
							sx={{
								background: planDetailsColors[category],
								borderRight: "1px solid #D0D0D0",
								writingMode: "vertical-rl",
								transform: "scale(-1)",
								width: 40,
								textAlign: "center",
								borderBottom: "1px solid #000",
								px: 1,
							}}>
							{category === "star" ? (
								<StarIcon width={24} fill="#E89201" transform="rotate(35)" />
							) : (
								<Typography sx={{ color: "white" }}>{category}</Typography>
							)}
						</Box>
						<div key={category}>
							{details.map((detail) => (
								<PlanRow key={detail.name} detail={detail} />
							))}
						</div>
					</Box>
				))}
			</Box>
		</Box>
	</Box>
);

export default PricingPlanComparison;
