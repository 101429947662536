import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	Court,
	Player,
	Reservation,
	Tournament,
	TournamentPlayer,
} from "../types/clublocker.types";
import moment from "moment";

const baseQuery = fetchBaseQuery({
	baseUrl: "https://api.ussquash.com/resources",
	prepareHeaders: (headers) => {
		const token = localStorage.getItem("clublocker_token");
		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}

		return headers;
	},
});

export const clublockerApi = createApi({
	reducerPath: "clublockerApi",
	baseQuery,
	endpoints: (builder) => ({
		getTournaments: builder.query<Tournament[], string | number>({
			query: (clubCode) =>
				`/trn/tournaments?TopRecords=50&VenueId=${clubCode}&Sanctioned=0&Status=1`,
		}),
		getTournamentPlayers: builder.query<TournamentPlayer[], string | number>({
			query: (id) => `/tournaments/${id}/entrants`,
		}),
		getCourts: builder.query<Court[], string | number>({
			query: (id) => `/res/clubs/${"10513"}/courts`,
		}),
		getReservations: builder.query<Reservation[], string | number>({
			query: (id) =>
				`/res/clubs/${"10513"}/slots_taken/${moment().format("YYYY-MM-DD")}`,
		}),
		getLeagues: builder.query<any, string | number>({
			query: (clubCode) =>
				`/resources/leagues?TopRecords=50&OrganizationId=1941&Admin=true&Status=0`,
		}),
	}),
});

export const {
	useGetTournamentsQuery,
	useGetTournamentPlayersQuery,
	useGetCourtsQuery,
	useGetReservationsQuery,
	useGetLeaguesQuery,
} = clublockerApi;
