// src/api/plan.api.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";

export const planApi = createApi({
	reducerPath: "planApi",
	baseQuery,
	endpoints: (builder) => ({
		startFreeTrial: builder.mutation<any, { planName: string; clubId: string }>(
			{
				query: (data) => ({
					url: "/club-plan-trial",
					method: "POST",
					body: data,
				}),
			}
		),
	}),
});

export const { useStartFreeTrialMutation } = planApi;
