import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";
import { ClubAdmin, FeesData } from "../types/clubhubApis.types";

export const clubApi = createApi({
	reducerPath: "clubApi",
	baseQuery,
	endpoints: (builder) => ({
		getClub: builder.query<any, string>({
			query: (clubId) => `/clubs/?name_unique=${clubId}`,
			transformResponse: (response: any) => response?.data[0] || null,
		}),
		saveClubDetails: builder.mutation<any, { id: string; data: FormData }>({
			query: ({ id, data }) => ({
				url: `/clubs/${id}`,
				method: "PATCH",
				body: data,
			}),
		}),
		getClubImages: builder.query<any, { venueName: string }>({
			query: ({ venueName }) =>
				`/club-gallery?club_name_unique=${venueName}&$sort[position]=-1`,
		}),
		removeImage: builder.mutation<any, string>({
			query: (id) => ({
				url: `/club-gallery/${id}`,
				method: "DELETE",
			}),
		}),
		updateClubImagePosition: builder.mutation<any, any>({
			query: (image) => ({
				url: `/club-gallery/${image._id}`,
				method: "PATCH",
				body: { position: image.position },
			}),
		}),
		addClubAdmin: builder.mutation<any, Omit<ClubAdmin, "_id">>({
			query: (admin) => ({
				url: "/club-admin",
				method: "POST",
				body: admin,
			}),
		}),
		removeClubAdmin: builder.mutation<any, { clubId: string; email: string }>({
			query: ({ clubId, email }) => ({
				url: `/club-admin/?clubId=${clubId}&email=${email}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const {
	useGetClubQuery,
	useSaveClubDetailsMutation,
	useGetClubImagesQuery,
	useRemoveImageMutation,
	useUpdateClubImagePositionMutation,
	useAddClubAdminMutation,
	useRemoveClubAdminMutation,
} = clubApi;
