import { Box, CircularProgress } from "@mui/joy"

export const LoaderOverlay = () => {
    return (
       <Box sx={{
              position: "fixed",
              width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                
       }}>
            <CircularProgress />
       </Box>
    );
    }