import { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Input,
	FormLabel,
	Textarea,
	Typography,
	Select,
	Option,
} from "@mui/joy";
import { ReactComponent as ArrowIcon } from "../Icons/arrow.svg";
import { nanoid } from "nanoid";
import { BlogPost } from "../types/clubhubApis.types";
import useBoxLeagues from "../hooks/useBoxLeagues";
import useTournaments from "../hooks/useTournaments";
import useLeagues from "../hooks/useLeagues";
import TextEditor from "./TextEditor";

interface BlogEditorProps {
	onSubmit: (
		formData: FormData,
		authorDescription: string | null
	) => Promise<void>;
	post?: BlogPost; // Optional for new posts
	isLoading: boolean;
}

const BlogEditor: React.FC<BlogEditorProps> = ({
	onSubmit,
	post,
	isLoading,
}) => {
	const [title, setTitle] = useState(post?.title || "");
	const [content, setContent] = useState(post?.content || "");
	const [photo, setPhoto] = useState<File | null>(null);
	const [location, setLocation] = useState(
		post?.tags.find((tag) => tag.includes("Location")) || ""
	);
	const [tagEvent, setTagEvent] = useState("");
	const [tagBoxLeague, setTagBoxLeague] = useState("");
	const [tagTeamsLeague, setTagTeamsLeague] = useState("");
	const [tagMembership, setTagMembership] = useState("");
	const [authorDescription, setAuthorDescription] = useState("");

	const { tournaments, isLoading: isLoadingTournaments } = useTournaments();
	const { boxLeagues, isLoading: isLoadingBoxLeagues } = useBoxLeagues();
	const { leagues, isLoading: isLoadingLeagues } = useLeagues();

	const authUser = useMemo(
		() => JSON.parse(localStorage.getItem("user") || "{}"),
		[]
	);

	useEffect(() => {
		if (authUser) {
			setAuthorDescription(authUser.description);
		}
	}, [authUser]);

	const handleSubmit = async () => {
		try {
			if (!title || !content || (!photo && !post)) {
				alert("All fields are required");
				return;
			}

			const formData = new FormData();
			formData.append("title", title);
			formData.append("content", content);
			formData.append("location", location);
			formData.append("tagEvent", tagEvent);
			formData.append("tagBoxLeague", tagBoxLeague);
			formData.append("tagTeamsLeague", tagTeamsLeague);
			formData.append("tagMembership", tagMembership);
			formData.append("clubId", "meadowparksportscentre");

			const imageName = photo ? nanoid() : post?.image;
			if (photo) {
				formData.append("images", photo, imageName!);
			}
			formData.append("image", imageName!);

			let _authorDescription: string | null = authorDescription;

			if (authorDescription === authUser.description) {
				_authorDescription = null;
			} else {
				localStorage.setItem(
					"user",
					JSON.stringify({ ...authUser, description: authorDescription })
				);
			}

			await onSubmit(formData, _authorDescription);
		} catch (error) {
			console.error(error);
			alert("An error occurred while creating the blog post");
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
			}}>
			<Typography level="h3">{post ? "EDIT" : "ADD"} BLOG POST</Typography>
			<Typography>All fields are required</Typography>
			<Input
				placeholder="Blog Title"
				value={title}
				onChange={(e) => setTitle(e.target.value)}
			/>
			<TextEditor
				value={content}
				onChange={(newContent) => setContent(newContent)}
				placeholder="Blog Content"
			/>
			<FormLabel>Photo</FormLabel>
			<Input
				type="file"
				sx={{ width: 300 }}
				onChange={(e) => setPhoto(e.target.files?.[0] || null)}
			/>
			<FormLabel>(Optional) Select a location for the post</FormLabel>
			<Input
				placeholder="Location"
				sx={{ width: 300 }}
				value={location}
				onChange={(e) => setLocation(e.target.value)}
			/>
			<FormLabel>
				(Optional) Select one event from one of the following Club Locker for
				the post
			</FormLabel>
			<Box
				sx={{
					display: "flex",
					gap: 5,
					flexWrap: "wrap",
				}}>
				<Select
					placeholder="Tag Event"
					indicator={<ArrowIcon width={16} height={16} />}>
					{tournaments.map((tournament) => (
						<Option
							key={tournament.TournamentID}
							value={tournament.TournamentID}>
							{tournament.TournamentName}
						</Option>
					))}
				</Select>
				<Select
					placeholder="Tag Box League"
					indicator={<ArrowIcon width={16} height={16} />}>
					{boxLeagues.map((boxLeague: any) => (
						<Option key={boxLeague.eventId} value={boxLeague.eventId}>
							{boxLeague.eventName}
						</Option>
					))}
				</Select>
				<Select
					placeholder="Tag Teams League"
					indicator={<ArrowIcon width={16} height={16} />}>
					{leagues.map((league: any) => (
						<Option key={league.leagueId} value={league.leagueId}>
							{league.leagueDescr}
						</Option>
					))}
				</Select>
				<Select
					placeholder="Tag Membership"
					indicator={<ArrowIcon width={16} height={16} />}></Select>
			</Box>
			<Box
				sx={{
					width: 350,
					height: "100%",
					my: 2,
				}}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}>
					<img
						src={
							"https://storage.googleapis.com/activscout/" + authUser.avatar ||
							""
						}
						alt="avatar"
						style={{
							width: 60,
							height: 60,
							borderRadius: "50%",
							objectFit: "cover",
						}}
					/>
					<Box>
						<Typography level="h4" sx={{ color: "#1f3a6e" }}>
							{authUser.firstName} {authUser.lastName}
						</Typography>
						<Typography level="body-md" sx={{ color: "#7f7f7f" }}>
							Meadow Park Recreation Centre
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography level="body-sm" sx={{ color: "#7f7f7f" }} mt={2}>
						<Textarea
							value={authorDescription}
							onChange={(e) => setAuthorDescription(e.target.value)}
							placeholder="Add a brief description of yourself"
						/>
					</Typography>
				</Box>
			</Box>
			<Button onClick={handleSubmit} loading={isLoading}>
				Submit
			</Button>
		</Box>
	);
};

export default BlogEditor;
