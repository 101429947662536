import {
	Box,
	Button,
	IconButton,
	Option,
	Radio,
	Select,
	Typography,
} from "@mui/joy";
import SectionHeader from "./SectionHeader";
import { ReactComponent as ArrowIcon } from "../Icons/arrow.svg";
import { CSSProperties, useEffect, useState } from "react";
import { useSaveClubDetailsMutation } from "../apis/clubhub.api";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";

const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const styles = {
	container: {
		display: "flex",
		flexDirection: {
			xs: "column",
			md: "row",
		},
		alignItems: {
			xs: "center",
			md: "flex-start",
		},
		gap: 4, // Add gap between columns
		mt: 3,
	},
	column: {
		p: 1,
		display: "flex",
		flexDirection: "column",
		gap: 1,
	},
	selectDaysBox: {
		display: "flex",
		gap: 2,
		alignItems: "center",
	},
	timeBox: {
		width: 80,
		height: 30,
		background: "#d0d0d0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	holidaySelect: {
		background: "#d0d0d0",
		width: 200,
		borderRadius: 5,
		overflowX: "hidden",
		overflowY: "hidden",
		height: 110,
	},
	scheduleBox: {
		background: "#d0d0d0",
		p: 1,
		width: 170,
		borderRadius: 5,
	},
	scheduleText: {
		fontWeight: "bold",
		color: "#7f7f7f",
	},
};

// Refactored DaySelection component with day management
const DaySelection = ({
	selectedDays,
	onDayChange,
	showAdd,
	onAdd,
	onRemove,
}: {
	selectedDays: string[];
	onDayChange: (day: string) => void;
	showAdd?: boolean;
	onAdd?: () => void;
	onRemove?: () => void;
}) => (
	<Box
		sx={[
			styles.column,
			{
				width: "auto",
				p: 0,
			},
		]}>
		<Box sx={styles.selectDaysBox}>
			<IconButton onClick={onRemove}>
				<DeleteOutline />
			</IconButton>

			{days.map((day) => (
				<Box key={day}>
					<Typography sx={{ color: "#7f7f7f" }}>{day}</Typography>
					<Box onClick={() => onDayChange(day)}>
						<Radio
							checked={selectedDays.includes(day)}
							disabled={!selectedDays.includes(day) && selectedDays.length >= 7} // Prevent selecting more than 7 days
						/>
					</Box>
				</Box>
			))}
			{showAdd && (
				<Box
					onClick={onAdd}
					sx={{
						width: 25,
						height: 25,
						background: "#d0d0d0",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
						borderRadius: "50%",
						mt: "auto",
					}}>
					<Add sx={{ fontSize: 16 }} />
				</Box>
			)}
		</Box>
	</Box>
);

// Refactored TimeSelection component with time management
const TimeSelection = ({
	start,
	end,
	closed,
	onTimeChange,
	onClosedChange,
}: {
	start: string;
	end: string;
	closed: boolean;
	onTimeChange: (type: "start" | "end", time: string) => void;
	onClosedChange: (closed: boolean) => void;
}) => {
	const [isClosed, setIsClosed] = useState(closed);

	const handleClosedChange = (value: boolean) => {
		setIsClosed(value);
		onClosedChange(value);
	};

	return (
		<Box
			sx={{
				...styles.column,
				width: "auto",
				alignItems: "center",
			}}>
			<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
				<Radio checked={!isClosed} onChange={() => handleClosedChange(false)} />
				<Box
					sx={styles.timeBox}
					onClick={() =>
						onTimeChange("start", prompt("Enter start time", start) || start)
					}>
					{start}
				</Box>
				<Typography>to</Typography>
				<Box
					sx={styles.timeBox}
					onClick={() =>
						onTimeChange("end", prompt("Enter end time", end) || end)
					}>
					{end}
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", ml: 3, gap: 1 }}>
					<Radio checked={isClosed} onChange={() => handleClosedChange(true)} />
					<Select
						disabled={!isClosed}
						value={"closed"}
						indicator={<ArrowIcon width={12} />}
						sx={{
							p: 1.2,
							height: 30,
							width: 110,
							bgcolor: "#d0d0d0",
							borderRadius: 1,
						}}>
						<Option value="closed">Closed</Option>
					</Select>
				</Box>
			</Box>
		</Box>
	);
};

// Refactored ScheduleDisplay component
const ScheduleDisplay = ({ operations }: { operations: Operation[] }) => (
	<Box
		sx={{
			...styles.column,
		}}>
		<Typography level="title-lg">Maximum 8 lines</Typography>
		<Box sx={styles.scheduleBox}>
			{operations.map((op, index) =>
				op.days.length ? (
					<Typography key={index} sx={{ color: "#7f7f7f" }}>
						{op.days.join(", ")}:{" "}
						{op.closed ? "Closed" : `${op.start} - ${op.end}`}
					</Typography>
				) : null
			)}
		</Box>
	</Box>
);

// Operation type definition
interface Operation {
	days: string[];
	start: string;
	end: string;
	closed: boolean;
}

interface HoursOfOperationProps {
	clubId: string;
	operations: Operation[];
}

const sampleOperation = {
	days: [],
	start: "6:00",
	end: "22:00",
	closed: false,
};

// HoursOfOperation main component
export default function HoursOfOperation({
	clubId,
	operations: _operations,
}: HoursOfOperationProps) {
	const [operations, setOperations] = useState<Operation[]>([sampleOperation]);

	const [updateDuration, { isLoading }] = useSaveClubDetailsMutation();

	useEffect(() => {
		if (_operations?.length) {
			setOperations(_operations);
		}
	}, [_operations]);

	// Handle day change
	const handleDayChange = (index: number, day: string) => {
		// if day is selected in any operation remove it
		const isDaySelected = operations.some((op) => op.days.includes(day));
		setOperations((prevOps) =>
			prevOps.map((op, opIndex) =>
				opIndex === index
					? {
							...op,
							days: isDaySelected
								? op.days.filter((d) => d !== day)
								: [...op.days, day],
					  }
					: op
			)
		);
	};

	// Handle time change
	const handleTimeChange = (
		index: number,
		type: "start" | "end",
		time: string
	) => {
		setOperations((prevOps) =>
			prevOps.map((op, opIndex) =>
				opIndex === index
					? {
							...op,
							[type]: time,
					  }
					: op
			)
		);
	};

	// Handle closed change
	const handleClosedChange = (index: number, closed: boolean) => {
		setOperations((prevOps) =>
			prevOps.map((op, opIndex) =>
				opIndex === index
					? {
							...op,
							closed,
					  }
					: op
			)
		);
	};

	const addOperation = () => {
		setOperations((prevOps) => [...prevOps, sampleOperation]);
	};

	const handleSave = async () => {
		try {
			await updateDuration({
				id: clubId,
				data: {
					operations: operations,
				} as any,
			});
			alert("Hours of operation saved successfully");
		} catch (error) {
			console.error(error);
			alert("An error occurred while saving hours of operation");
		}
	};

	const removeOperation = (index: number) => {
		if (operations.length === 1) return;
		setOperations((prevOps) => prevOps.filter((_, i) => i !== index));
	};

	return (
		<Box mt={5}>
			<SectionHeader title="HOURS OF OPERATION" />
			<Box sx={styles.container}>
				<Box
					sx={[
						styles.column,
						{
							width: {
								xs: "auto",
								md: "30%",
							},
						},
					]}>
					<Typography level="title-lg">Select Days</Typography>
					{operations.map((operation, index) => (
						<DaySelection
							key={index}
							selectedDays={operation.days}
							onDayChange={(day) => handleDayChange(index, day)}
							showAdd={index === 0}
							onAdd={addOperation}
							onRemove={() => removeOperation(index)}
						/>
					))}
				</Box>
				<Box
					sx={{
						width: {
							xs: "auto",
							md: "40%",
						},
						pt: 1,
					}}>
					<Typography level="title-lg" textAlign={"center"}>
						Provide Start and Finish Hours (24 hour Clock)
					</Typography>
					{operations.map((operation, index) => (
						<TimeSelection
							key={index}
							start={operation.start}
							end={operation.end}
							closed={operation.closed}
							onTimeChange={(type, time) => handleTimeChange(index, type, time)}
							onClosedChange={(closed) => handleClosedChange(index, closed)}
						/>
					))}
				</Box>
				<Box
					sx={{
						width: {
							xs: "auto",
							md: "30%",
						},
					}}>
					<ScheduleDisplay operations={operations} />
					<Button
						onClick={handleSave}
						loading={isLoading}
						sx={{ mt: 2, width: 170 }}>
						Submit
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
