import React, { useMemo, useEffect, useState } from "react";
import TimeList, { getTimesArr } from "./TimeList";
import { Box, Button, Typography } from "@mui/joy";
import plus from "../../assets/plus.png";
import moment from "moment";
import axios from "axios";
import {
	useGetClubQuery,
	useGetCourtsQuery,
	useGetReservationsQuery,
} from "../../apis/clubhub.api";
import { useParams } from "react-router-dom";
import ClublockerLoginModal from "../ClublockerLogin";
import CourtBookingCrowdSource from "../CourtBookingCrowdSource";

export default function CourtBookingComponent() {
	const [clublockerLoginModalVisible, setClublockerLoginModalVisible] =
		useState(false);
	const venueName = useParams<{ venueName: string }>().venueName || "";

	const { data: club, isLoading: isClubLoading } = useGetClubQuery(venueName, {
		skip: !venueName,
	});

	const {
		data: courts,
		isLoading: isCourtsLoading,
		refetch: refetchCourts,
	} = useGetCourtsQuery(club?.clubCode, {
		skip: !club,
	});
	const {
		data: reservations,
		isLoading: isReservationsLoading,
		refetch: refetchReservations,
	} = useGetReservationsQuery("10513", {
		skip: !club,
	});

	const generateBlocksForCourt = (court: any) => {
		// Generate time blocks for each court based on its slot length and start-end times
		return getTimesArr(
			court.firstSlotStartTime,
			court.lastSlotEndTime,
			court.slotLengthMinutes
		);
	};

	const isSlotReserved = (courtId: any, timeBlock: any) => {
		// Check if the time block for a specific court is reserved
		const time = moment(timeBlock, "HH:mm");

		// Check if the time block is in the past, if so return reserved/true
		// if (time.isAfter(moment())) return true;

		return reservations?.some(
			(reservation) =>
				reservation.court === courtId &&
				moment(reservation.slot.split("-")[0], "HH:mm").isSame(time)
		);
	};

	const onClublockerLogin = async () => {
		window.location.reload();
	};

	const isLoggedIn = useMemo(() => !!localStorage.getItem("clubhub_token"), []);

	if (!isLoggedIn)
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: 2,
				}}>
				<Typography textAlign={"center"}>
					Please login to Clublocker to book a court
				</Typography>
				<Button
					sx={{ margin: "auto" }}
					onClick={() => {
						setClublockerLoginModalVisible(true);
					}}>
					Login
				</Button>
				<ClublockerLoginModal
					open={clublockerLoginModalVisible}
					onClose={() => setClublockerLoginModalVisible(false)}
					onLogin={onClublockerLogin}
				/>
			</Box>
		);

	if (isClubLoading || isCourtsLoading || isReservationsLoading) {
		return <Typography>Loading...</Typography>;
	}

	if (!courts?.length) {
		return <CourtBookingCrowdSource clubCode={club?.clubCode} />;
	}

	return (
		<Box mt={5}>
			<Box
				sx={{
					display: "flex",
					width: { xs: "100vw", sm: "auto" },
				}}>
				<TimeList startTime="06:00" endTime="22:00" length={40} />

				<Box
					sx={{
						display: "flex",
						overflowX: { xs: "scroll", sm: "hidden" },
					}}>
					{courts?.map((court) => (
						<Box key={court.id}>
							<Box
								sx={{
									width: "100px",
									height: "30px",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
								}}>
								<Typography fontSize={14} sx={{ color: " #000" }}>
									{court.name?.replace("#", "")}
								</Typography>
							</Box>

							{/* Render time blocks dynamically */}
							{generateBlocksForCourt(court).map((timeBlock, index) => (
								<Box
									key={`${court.id}-${index}`}
									sx={{
										width: "100px",
										height: "30px",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										textAlign: "center",
										border: "1px solid #C9C9C9",
										background: isSlotReserved(court.id, timeBlock)
											? "#d0d0d0" // reserved slot color
											: "#a8becb", // open slot color
										cursor: "pointer",
									}}>
									{isSlotReserved(court.id, timeBlock) ? null : (
										<img
											src={plus}
											alt="plus"
											style={{
												width: 16,
												height: 16,
											}}
										/>
									)}
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}
