import Hero from "../Components/Hero";
import Mission from "../Components/Mission";
import StakeHolders from "../Components/StakeHolders";
import Partners from "../Components/Partners";
import ContactUs from "../Components/ContactUs";
import UniteAndGrow from "../Components/UniteAndGrow";
import Footer from "../Components/Footer";
import { useEffect, useRef } from "react";
import NewsCarousel from "../Components/NewsCarousel";
import CourtFinderMap from "../Components/CourtFinderMap";
import PartnerTestimonials from "../Components/PartnerTestimonials";

function Home() {
	const aboutRef = useRef(null);
	const teamRef = useRef(null);
	const contactUsRef = useRef(null);
	const newsRef = useRef(null);

	useEffect(() => {
		if (window.location.hash === "#contact") {
			// @ts-ignore
			contactUsRef.current?.scrollIntoView?.({ behavior: "smooth" });
		}
	}, [contactUsRef]);

	return (
		<>
			<Hero
				aboutRef={aboutRef}
				partnerNetworkRef={teamRef}
				contactUsRef={contactUsRef}
				newsRef={newsRef}
			/>
			<div ref={aboutRef}>
				<StakeHolders />
				{/* <Partners /> */}
			</div>
			<div ref={newsRef}>
				<NewsCarousel />
			</div>
			<CourtFinderMap />
			<div ref={teamRef}>
				<Partners />
			</div>
			<PartnerTestimonials />
			<div ref={contactUsRef}>
				<ContactUs />
			</div>
			<Footer blogRef={newsRef} isClubSite={false} />
		</>
	);
}

export default Home;
