import { Typography } from "@mui/joy";
import moment from "moment";
import { useMemo } from "react";

export const getTimesArr = (start = "9:45", end = "23:00", interval = 45) => {
	const intervals = [];
	const currentTime = moment(start, "HH:mm");
	const endTime = moment(end, "HH:mm");

	while (currentTime <= endTime) {
		intervals.push(currentTime.format("HH:mm"));
		currentTime.add(interval, "minutes");
	}

	return intervals;
};

interface TimeListProps {
	startTime: string;
	endTime: string;
	length: number;
}

function TimeList({ startTime, endTime, length }: TimeListProps) {
	const timesArray = useMemo(
		() => ["Court Times", ...getTimesArr(startTime, endTime, length)],
		[startTime, endTime, length]
	);

	return (
		<div>
			{timesArray.map((item, i) => (
				<div
					key={item}
					style={{
						width: "100px",
						height: "32px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						// border: "1px solid #C9C9C9",
						// borderRight: i ? "1px solid #C9C9C9" : "none",
						// background: "#E1E1E1",
					}}>
					<Typography
						sx={{
							width: "100px",
							height: "19px",
							fontSize: "14px",
							fontWeight: "normal",
							fontStyle: "normal",
							lineHeight: "19px",
							letterSpacing: "0",
							textAlign: "center",
							color: " #000",
						}}>
						{item}
					</Typography>
				</div>
			))}
		</div>
	);
}

export default TimeList;
