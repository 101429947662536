import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/joy";
import ImageViewer from "./ImageViewer";
import { Delete, PlayArrow } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
	useGetClubImagesQuery,
	useRemoveImageMutation,
} from "../apis/clubhub.api";
import { useParams } from "react-router-dom";
type Image = {
	image?: string;
	asset?: string;
	title?: string;
	_id?: string;
	position?: number;
	type?: string;
};

interface ImageCarouselProps {
	data: Image[];
	imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
	showDelete?: boolean;
	onChange?: (
		images: Image[],
		changedImage1: Image,
		changeImages2: Image
	) => void;
}

const ImageGrid = styled(Box)`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 16px;
	padding: 16px;

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const ImageItem = styled(Box)`
	text-align: center;
	width: 100%;
	cursor: pointer;
	position: relative;
	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
		border-radius: 10px;
	}
`;

const GalleryComponent: React.FC<ImageCarouselProps> = ({
	data,
	imageProps,
	showDelete = false,
	onChange,
}) => {
	const [selectedImage, setSelectedImage] = useState<Image | null>(null);
	const venueName = useParams<{ venueName: string }>().venueName || "";
	const { refetch } = useGetClubImagesQuery(
		{ venueName },
		{ skip: !venueName }
	);

	const [deleteImage] = useRemoveImageMutation();

	const onRemoveImage = async (imageId: string) => {
		try {
			const confirm = window.confirm(
				"Are you sure you want to delete this image?"
			);
			if (!confirm) return;
			await deleteImage(imageId).unwrap();
			await refetch();
			alert("Image deleted successfully");
		} catch (error) {
			console.error(error);
		}
	};

	// Handle drag end for swapping images
	const onDragEnd = (result: any) => {
		const { source, destination } = result;
		if (!destination) return;

		// Swap only source and destination items
		const updatedImages = [...data];

		[updatedImages[source.index], updatedImages[destination.index]] = [
			updatedImages[destination.index],
			updatedImages[source.index],
		];

		onChange &&
			onChange(
				updatedImages,
				{
					...updatedImages[source.index],
					position: updatedImages[destination.index].position,
				},
				{
					...updatedImages[destination.index],
					position: updatedImages[source.index].position,
				}
			);
	};

	return (
		<Box>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable
					droppableId="imageGrid"
					direction="horizontal"
					isDropDisabled>
					{(provided: any) => (
						<ImageGrid ref={provided.innerRef} {...provided.droppableProps}>
							{data.map((image, index) => {
								const img = image.asset
									? "https://storage.googleapis.com/activscout/" + image.asset
									: image.image;
								return (
									<Draggable
										isDragDisabled={!onChange}
										key={image._id}
										draggableId={image._id || index.toString()}
										index={index}>
										{(provided: any) => (
											<ImageItem
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												onClick={() => setSelectedImage(image)}>
												{showDelete && (
													<Delete
														onClick={(e) => {
															e.stopPropagation();
															onRemoveImage(image._id || "");
														}}
														sx={{
															position: "absolute",
															right: 10,
															color: "white",
															background: "rgba(0,0,0,0.5)",
															borderRadius: "50%",
															cursor: "pointer",
															padding: "5px",
															zIndex: 999,
															fontSize: 30,
														}}
													/>
												)}
												{image.type === "video/mp4" ? (
													<Box
														sx={{
															position: "relative",
															overflow: "hidden",
															borderRadius: "10px",
														}}>
														<PlayArrow
															sx={{
																position: "absolute",
																top: "50%",
																left: "50%",
																transform: "translate(-50%, -50%)",
																color: "white",
																fontSize: 50,
																zIndex: 999,
															}}
														/>
														<Box
															sx={{
																position: "absolute",
																top: 0,
																left: 0,
																width: "100%",
																height: "100%",
																background: "rgba(0,0,0,0.5)",
																zIndex: 1,
															}}
														/>
														<video
															src={img}
															style={{
																width: "100%",
																height: "200px",
																objectFit: "cover",
																...(imageProps?.style || {}),
															}}
															{...(imageProps as React.VideoHTMLAttributes<HTMLVideoElement>)}
														/>
													</Box>
												) : (
													<img
														src={img}
														alt={image.title || "image"}
														{...imageProps}
														style={{
															objectFit: "cover",
															...(imageProps?.style || {}),
														}}
													/>
												)}
												{image.title && (
													<Typography level="h4" mt={2}>
														{image.title}
													</Typography>
												)}
											</ImageItem>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</ImageGrid>
					)}
				</Droppable>
			</DragDropContext>
			<ImageViewer
				src={
					selectedImage?.asset
						? (("https://storage.googleapis.com/activscout/" +
								selectedImage?.asset) as string)
						: ""
				}
				type={selectedImage?.type}
			/>
		</Box>
	);
};

export default GalleryComponent;
