import { Box, Button } from "@mui/joy";
import SectionHeader from "../../Components/SectionHeader";
import GalleryComponent from "../../Components/GalleryComponent";
import { NavHeader } from "../../Components/Header";
import AdminHeader from "../../Components/AdminHeader";
import ImageUploadModal from "../../Components/ImageUploadModal";
import { useState } from "react";
import clubHubApi, { useGetClubImagesQuery } from "../../apis/clubhub.api";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import { useDispatch } from "react-redux";

const AdminGallery = () => {
	const [ImageUploadModalOpen, setImageUploadModalOpen] = useState(false);

	const dispatch = useDispatch();
	const [updateClubImagePosition] =
		clubHubApi.useUpdateClubImagePositionMutation();
	const venueName = useParams<{ venueName: string }>().venueName || "";
	const {
		data: images,
		isLoading,
		refetch,
	} = useGetClubImagesQuery(
		{ venueName },
		{
			skip: !venueName,
		}
	);

	const onImagesChange = async (images: any, ...swappedImages: any[]) => {
		dispatch(
			clubHubApi.util.updateQueryData(
				"getClubImages",
				{ venueName },
				(data) => {
					return { ...data, data: images };
				}
			)
		);

		await updateClubImagePosition(swappedImages[0]);
		await updateClubImagePosition(swappedImages[1]);
	};

	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: 5,
					pb: 12,
				}}>
				<SectionHeader
					title="SQUASH GALLERY"
					showBorder
					icon={require("../../assets/Squash Court.png")}
					iconStyle={{
						width: 30,
						marginRight: "auto",
						position: "absolute",
						left: 0,
					}}
				/>
				<GalleryComponent
					data={images?.data || []}
					showDelete
					onChange={onImagesChange}
				/>
				<Button
					onClick={() => setImageUploadModalOpen(true)}
					sx={{
						float: "right",
						mt: 2,
						mr: 2,
						width: 150,
						borderRadius: 0,
					}}>
					Upload media
				</Button>
			</Box>
			<ImageUploadModal
				open={ImageUploadModalOpen}
				onClose={() => {
					setImageUploadModalOpen(false);
					refetch();
				}}
			/>
			<Footer />
		</div>
	);
};

export default AdminGallery;
