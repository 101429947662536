import { useParams } from "react-router-dom";
import { useGetClubQuery, useGetBoxLeaguesQuery } from "../apis/clubhub.api";

const useBoxLeagues = () => {
	const venueName = (useParams().venueName as string | undefined) || "";

	const { data: club } = useGetClubQuery(venueName, {
		skip: !venueName,
	});

	const { data: boxLeagues = [], isLoading } = useGetBoxLeaguesQuery(
		club?.clubCode || "",
		{
			skip: !club?.clubCode,
		}
	);

	return { boxLeagues, isLoading };
};

export default useBoxLeagues;
