import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	BlogPost,
	BlogPostsResponse,
	ClubAdmin,
	FeesData,
} from "../types/clubhubApis.types";
import moment from "moment";
import { Court, Reservation } from "../types/clublocker.types";

export const clubHubApi = createApi({
	reducerPath: "clubhubApi",
	baseQuery: fetchBaseQuery({
		// baseUrl: "http://localhost:8080",
		baseUrl: "https://backend.clubhub.net",
		headers: new Headers({
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("clubhub_token")}`,
		}),
	}),
	endpoints: (builder) => ({
		clublockerLogin: builder.mutation({
			query: (credentials) => ({
				url: "/club-locker-login",
				method: "POST",
				body: credentials,
			}),
		}),
		login: builder.mutation({
			query: (credentials) => ({
				url: "/authentication",
				method: "POST",
				body: {
					email: credentials.username,
					password: credentials.password,
					strategy: "clublocker",
				},
			}),
		}),
		createBlogPost: builder.mutation<any, FormData>({
			query: (post) => ({
				url: "/blog-post",
				method: "POST",
				body: post,
				headers: new Headers({
					Authorization: `Bearer ${localStorage.getItem("clubhub_token")}`,
					contentType: "multipart/form-data",
				}),
			}),
		}),
		updateBlogPost: builder.mutation<any, { post: FormData; id: string }>({
			query: ({ post, id }) => ({
				url: "/blog-post/" + id,
				method: "PATCH",
				body: post,
				headers: new Headers({
					Authorization: `Bearer ${localStorage.getItem("clubhub_token")}`,
					contentType: "multipart/form-data",
				}),
			}),
		}),
		getBlogPosts: builder.query<BlogPostsResponse, string>({
			query: (clubId) => ({
				url: `/blog-post?clubId=${clubId}&$sort[position]=1`,
				method: "GET",
			}),
		}),
		removeBlogPost: builder.mutation<any, string>({
			query: (id) => ({
				url: "/blog-post/" + id,
				method: "DELETE",
			}),
		}),
		getClub: builder.query<any, string>({
			query: (clubId) => ({
				url: `/clubs/?name_unique=${clubId}`,
				method: "GET",
			}),
			transformResponse(baseQueryReturnValue, meta, arg) {
				//@ts-ignore
				return baseQueryReturnValue?.data[0] || null;
			},
		}),
		saveClubDetails: builder.mutation<
			any,
			{
				id: string;
				data: FormData;
			}
		>({
			query: ({ id, data }) => ({
				url: "/clubs/" + id,
				method: "PATCH",
				body: data,
			}),
		}),
		getFeesData: builder.query<any, string>({
			query: (clubId) => ({
				url: `/club-fees?clubId=${clubId}`,
				method: "GET",
			}),
			transformResponse(baseQueryReturnValue: any, meta, arg) {
				return baseQueryReturnValue.data;
			},
		}),
		saveClubFees: builder.mutation<any, FeesData>({
			query: (feesData) => ({
				url: "/club-fees",
				method: "POST",
				body: feesData,
			}),
		}),
		updateClubFees: builder.mutation<any, FeesData>({
			query: (feesData) => ({
				url: "/club-fees/" + feesData._id,
				method: "PATCH",
				body: feesData,
			}),
		}),
		getBlogPost: builder.query<BlogPost, string>({
			query: (id) => ({
				url: `/blog-post/${id}`,
				method: "GET",
			}),
		}),
		getClubImages: builder.query<
			any,
			{
				venueName: string;
			}
		>({
			query: ({ venueName }) => ({
				url: `/club-gallery?club_name_unique=${venueName}&$sort[position]=-1`,
				method: "GET",
			}),
		}),
		getCourts: builder.query<Court[], string>({
			query: (clubId) => ({
				url: `/clublocker-api/getCourts/?clubCode=${clubId}`,
				method: "GET",
			}),
		}),
		getReservations: builder.query<Reservation[], string>({
			query: (clubId) => ({
				url: `/clublocker-api/getReservations/?clubCode=${clubId}&date=${moment().format(
					"YYYY-MM-DD"
				)}`,
				method: "GET",
			}),
		}),
		removeImage: builder.mutation<any, string>({
			query: (id) => ({
				url: "/club-gallery/" + id,
				method: "DELETE",
			}),
		}),
		updateClubImagePosition: builder.mutation<any, any>({
			query: (image) => ({
				url: "/club-gallery/" + image._id,
				method: "PATCH",
				body: {
					position: image.position,
				},
			}),
		}),
		addClubAdmin: builder.mutation<any, Omit<ClubAdmin, "_id">>({
			query: (admin) => ({
				url: "/club-admin",
				method: "POST",
				body: admin,
			}),
		}),
		removeClubAdmin: builder.mutation<
			any,
			{
				clubId: string;
				email: string;
			}
		>({
			query: ({ clubId, email }) => ({
				url: `/club-admin/?clubId=${clubId}&email=${email}`,
				method: "DELETE",
			}),
		}),
		updateAuthorDescription: builder.mutation<any, string>({
			query: (description) => ({
				url: `/change-profile`,
				method: "POST",
				body: { authorDescription: description },
			}),
		}),
		getBoxLeagues: builder.query<any, string>({
			query: (clubId) => ({
				url: `/clublocker-api/getBoxLeagueByClub/?clubId=${clubId}`,
				method: "GET",
			}),
		}),
		getLeagues: builder.query<
			any,
			{
				clubId: string;
				includePast?: boolean;
			}
		>({
			query: ({ clubId, includePast }) => ({
				url: `/clublocker-api/getLeagues/?clubCode=${clubId}&past=${includePast}`,
				method: "GET",
			}),
		}),

		requestOtp: builder.mutation<any, string>({
			query: (email) => ({
				url: "/signup-passwordless-user",
				method: "POST",
				body: {
					email,
				},
			}),
		}),
		otpLogin: builder.mutation<any, { email: string; otp: string }>({
			query: ({ email, otp }) => ({
				url: "/authentication",
				method: "POST",
				body: {
					strategy: "otp",
					email: email,
					otp: otp,
				},
			}),
		}),
		saveCrowdCourtBooking: builder.mutation<any, any>({
			query: (data) => ({
				url: "/crowd-court-booking",
				method: "POST",
				body: data,
			}),
		}),
		updateCrowdCourtBooking: builder.mutation<any, any>({
			query: (data) => ({
				url: "/crowd-court-booking/" + data._id,
				method: "PATCH",
				body: {
					bookingSystem: data.bookingSystem,
				},
			}),
		}),
	}),
});

export const {
	useClublockerLoginMutation,
	useCreateBlogPostMutation,
	useGetBlogPostsQuery,
	useGetClubQuery,
	useSaveClubDetailsMutation,
	useUpdateBlogPostMutation,
	useSaveClubFeesMutation,
	useUpdateClubFeesMutation,
	useGetFeesDataQuery,
	useGetBlogPostQuery,
	useLoginMutation,
	useGetClubImagesQuery,
	useRemoveBlogPostMutation,
	useGetCourtsQuery,
	useGetReservationsQuery,
	useRemoveImageMutation,
	useUpdateClubImagePositionMutation,
	useAddClubAdminMutation,
	useRemoveClubAdminMutation,
	useUpdateAuthorDescriptionMutation,
	useGetBoxLeaguesQuery,
	useGetLeaguesQuery,
	useRequestOtpMutation,
	useOtpLoginMutation,
	useSaveCrowdCourtBookingMutation,
	useUpdateCrowdCourtBookingMutation,
} = clubHubApi;

export default clubHubApi;
