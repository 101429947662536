import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery,
	endpoints: (builder) => ({
		clublockerLogin: builder.mutation({
			query: (credentials) => ({
				url: "/club-locker-login",
				method: "POST",
				body: credentials,
			}),
		}),
		login: builder.mutation({
			query: (credentials) => ({
				url: "/authentication",
				method: "POST",
				body: {
					email: credentials.username,
					password: credentials.password,
					strategy: "clublocker",
				},
			}),
		}),
		requestOtp: builder.mutation({
			query: (email) => ({
				url: "/signup-passwordless-user",
				method: "POST",
				body: { email },
			}),
		}),
		otpLogin: builder.mutation({
			query: ({ email, otp }) => ({
				url: "/authentication",
				method: "POST",
				body: {
					strategy: "otp",
					email,
					otp,
				},
			}),
		}),
	}),
});

export const {
	useClublockerLoginMutation,
	useLoginMutation,
	useRequestOtpMutation,
	useOtpLoginMutation,
} = authApi;
