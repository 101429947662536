import React, { CSSProperties } from "react";
import squashCourtIcon from "../assets/Squash Court.png";
import { Box, Typography } from "@mui/joy";

const styles = {
	squashCourtHeader: {
		// borderBottom: 1,
		display: "flex",
		gap: 2,
		pb: 0.5,
		textAlign: "center",
		alignItems: "center",
		height: {
			xs: "auto",
			md: 50,
		},
		minHeight: 50,
		position: "relative",
		justifyContent: "center",
	},
};

interface SectionHeaderProps {
	icon?: any;
	iconStyle?: CSSProperties;
	title: string;
	titleColor?: string;
	borderColor?: string;
	showBorder?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
	icon,
	iconStyle,
	title,
	titleColor = "#1F3A6E",
	borderColor = "#9BAFBC",
	showBorder = false,
}) => {
	return (
		<Box
			sx={[
				styles.squashCourtHeader,
				{
					borderColor: borderColor,
					borderBottom: showBorder ? 1 : 0,
				},
			]}>
			{icon && <img src={icon} alt="" style={iconStyle} />}
			<Typography
				level={"h1"}
				sx={{
					textAlign: "center",
					color: titleColor,
					bottom: 0,
					fontWeight: "400",
				}}>
				{title}
			</Typography>
		</Box>
	);
};

export default SectionHeader;
