import { Box, Table, Typography, Input } from "@mui/joy";
import React, { useState } from "react";
import SectionHeader from "../../Components/SectionHeader";
import FeesAndMemberships from "../../Components/FeesAndMemberships";
import AdminHeader from "../../Components/AdminHeader";
import Footer from "../../Components/Footer";

const FeesAndMembershipsScreen = () => {
	return (
		<Box>
			<AdminHeader />
			<Box
				sx={{
					p: 5,
				}}>
				<FeesAndMemberships editable={true} />
			</Box>
			<Footer />
		</Box>
	);
};

export default FeesAndMembershipsScreen;
