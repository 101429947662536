import {
	Box,
	Button,
	Checkbox,
	Input,
	Modal,
	ModalDialog,
	Typography,
} from "@mui/joy";
import { FC, useEffect, useState } from "react";
import {
	useGetClubQuery,
	useLoginMutation,
	useOtpLoginMutation,
	useRequestOtpMutation,
} from "../apis/clubhub.api";
import { useParams } from "react-router-dom";

interface LoginModalProps {
	open: boolean;
	onClose: () => void;
	onLogin: () => void;
}

const styles: {
	[key: string]: React.CSSProperties;
} = {
	modalDialog: {
		width: 500,
	},
	headerBox: {
		display: "flex",
		alignItems: "center",
	},
	loginBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 1,
		width: "50%",
		padding: 1,
		clipPath: "polygon(0 0, 90% 0, 100% 100%, 0% 100%)",
		borderRadius: "5px 0 0 0",
	},
	otherLoginBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 1,
		width: "50%",
		padding: 1,
		clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0% 100%)",
		borderRadius: "0 5px 0 0",
	},
	signInBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 1,
		width: "50%",
		padding: 1,
	},
	contentBox: {
		background: "#d0d0d0d0",
		padding: 2,
		display: "flex",
		flexDirection: "column",
		gap: 2,
		borderRadius: "0 5px 5px 5px",
	},
	input: {
		background: "#d0d0d0d0",
	},
	passwordInput: {
		background: "#d0d0d0",
	},
};

const LoginModal: FC<LoginModalProps> = ({ open, onClose, onLogin }) => {
	const [currentTab, setCurrentTab] = useState("clublocker");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [optRequested, setOptRequested] = useState(false);

	const [login, { isLoading }] = useLoginMutation();

	const venueName = useParams<{ venueName: string }>().venueName || "";
	const { data: club } = useGetClubQuery(venueName, { skip: !venueName });

	const [requestOtp, { isLoading: requestingOtp }] = useRequestOtpMutation();
	const [otpLogin, { isLoading: logginIn }] = useOtpLoginMutation();

	useEffect(() => {
		setEmail("");
		setPassword("");
		setOptRequested(false);
	}, [currentTab]);

	const handleLogin = async () => {
		try {
			let data;
			if (currentTab === "clublocker") {
				if (!email || !password)
					return alert("Please enter email and password");
				data = await login({ username: email, password }).unwrap();
				if (!club) return alert("Club not found");
			} else {
				if (!email) return alert("Please enter email");

				if (!optRequested) {
					await requestOtp(email);
					setOptRequested(true);
					return;
				} else {
					if (!password) return alert("Please enter OTP");
					data = await otpLogin({ email, otp: password }).unwrap();
				}
			}

			if (data.accessToken) {
				// check if user is admin
				const userClub = data.user?.clubRights?.find(
					(_club: any) => club.clubCode === _club.clubCode
				);

				const isSubAdmin = club.admins.find(
					(admin: any) => admin.email === email
				);

				if (userClub?.type === "admin" || isSubAdmin) {
					localStorage.setItem("clubhub_token", data.accessToken);
					localStorage.setItem("clublocker_token", data.user?.clubLockerToken);
					localStorage.setItem(
						"clublocker_user_id",
						data.user?.clubLockerUserId
					);
					localStorage.setItem("user", JSON.stringify(data.user));

					onLogin();
				} else {
					alert("You are not an admin at this club");
					return;
				}
			}
		} catch (error: any) {
			if (error.data?.message === "Invalid username or password") {
				alert("Invalid username or password");
			}
			console.error(error);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog sx={styles.modalDialog}>
				<Typography level="h4">Attention:</Typography>
				<Typography level="body-sm">
					Administrative rights are currently only openly available in North
					America and Sweden to Club Locker Administrators.
				</Typography>

				<Typography level="body-sm">
					If you are outside North America and would like to claim your website
					as an administrator feel free to{" "}
					<Typography
						onClick={() => (window.location.href = "/#contact")}
						level="body-sm"
						sx={{ color: "#1782FF", cursor: "pointer" }}>
						Contact us
					</Typography>
				</Typography>
				<Box>
					<Box sx={styles.headerBox}>
						<Box
							onClick={() => setCurrentTab("clublocker")}
							sx={{
								...styles.loginBox,
								background: currentTab === "clublocker" ? "#f6f6f6" : "",
								cursor: "pointer",
							}}>
							<Typography level="title-lg">Club Locker Login</Typography>
						</Box>
						<Box
							onClick={() => setCurrentTab("other")}
							sx={{
								...styles.otherLoginBox,
								background: currentTab === "other" ? "#dbdbdbd0" : "",
								cursor: "pointer",
							}}>
							<Typography level="title-lg">Other Login</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							...styles.contentBox,
							background: currentTab === "other" ? "#dbdbdbd0" : "#f6f6f6",
						}}>
						<Input
							placeholder="email address"
							variant="plain"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							sx={styles.input}
						/>
						<Input
							placeholder={currentTab === "clublocker" ? "Password" : "OTP"}
							variant="plain"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							sx={styles.passwordInput}
							disabled={currentTab === "other" && !optRequested}
							type="password"
						/>
						<Checkbox label="Remember Me" />
						<Button
							loading={isLoading || requestingOtp || logginIn}
							onClick={handleLogin}>
							{currentTab === "other" && !optRequested
								? "Request OTP"
								: "Login"}
						</Button>
					</Box>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default LoginModal;
