import { useEffect, useState } from "react";
import { useGetClubQuery } from "../apis/clubhub.api";

const useIsAdmin = (venueName: string) => {
	const [isAdmin, setIsAdmin] = useState<null | Boolean>(null);

	const { data: club } = useGetClubQuery(venueName, {
		skip: !venueName,
	});

	useEffect(() => {
		if (club) {
			const user = JSON.parse(localStorage.getItem("user") || "{}");

			const userClub = user.clubRights?.find(
				(c: any) => c.clubCode === club.clubCode
			);

			if (userClub) {
				setIsAdmin(userClub.type === "admin");
			} else {
				setIsAdmin(false);
			}
		} else if (club === null) {
			setIsAdmin(false);
		}
	}, [club]);

	return isAdmin;
};

export default useIsAdmin;
