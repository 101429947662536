import {
	Box,
	Button,
	Checkbox,
	Input,
	Modal,
	ModalDialog,
	Typography,
} from "@mui/joy";
import { FC, useState } from "react";
import { useGetClubQuery, useLoginMutation } from "../apis/clubhub.api";
import { useParams } from "react-router-dom";

interface ClublockerLoginModalProps {
	open: boolean;
	onClose: () => void;
	onLogin: () => void;
}

const styles: {
	[key: string]: React.CSSProperties;
} = {
	modalDialog: {
		width: 500,
	},
	headerBox: {
		display: "flex",
		alignItems: "center",
	},
	loginBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 1,
		width: "50%",
		background: "#d0d0d0d0",
		padding: 1,
		clipPath: "polygon(0 0, 90% 0, 100% 100%, 0% 100%)",
		borderRadius: "5px 0 0 0",
	},
	signInBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 1,
		width: "50%",
		padding: 1,
	},
	contentBox: {
		display: "flex",
		flexDirection: "column",
		gap: 2,
		borderRadius: "0 5px 5px 5px",
	},
	input: {
		background: "#d0d0d0d0",
	},
	passwordInput: {
		background: "#d0d0d0",
	},
};

const ClublockerLoginModal: FC<ClublockerLoginModalProps> = ({
	open,
	onClose,
	onLogin,
}) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [login, { isLoading }] = useLoginMutation();

	const venueName = useParams<{ venueName: string }>().venueName || "";
	const { data: club } = useGetClubQuery(venueName, { skip: !venueName });

	const handleLogin = async () => {
		try {
			if (!email || !password) return alert("Please enter email and password");
			const res = await login({ username: email, password });

			if (!club) return alert("Club not found");

			if (res.data.accessToken) {
				// check if user is admin
				const userClub = res.data.user?.clubRights.find(
					(_club: any) => club.clubCode === _club.clubCode
				);

				if (!userClub || userClub.type !== "admin") {
					alert("You are not an admin at this club");
					return;
				}

				localStorage.setItem("clubhub_token", res.data.accessToken);
				localStorage.setItem(
					"clublocker_token",
					res.data.user?.clubLockerToken
				);
				localStorage.setItem(
					"clublocker_user_id",
					res.data.user?.clubLockerUserId
				);
				localStorage.setItem("user", JSON.stringify(res.data.user));

				onLogin();
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog sx={styles.modalDialog}>
				<Typography level="h4">CLUBLOCKER LOGIN</Typography>
				<Box>
					<Box sx={styles.contentBox}>
						<Input
							placeholder="email address"
							variant="plain"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							sx={styles.input}
						/>
						<Input
							placeholder="password"
							variant="plain"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							sx={styles.passwordInput}
							type="password"
						/>
						<Checkbox label="Remember Me" />
						<Button loading={isLoading} onClick={handleLogin}>
							Login
						</Button>
					</Box>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default ClublockerLoginModal;
