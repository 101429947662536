import { Box, Button, Input, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import Header, { NavHeader } from "../Components/Header";
import CourtBookingComponent from "../Components/CourtBookingComponent";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import SectionHeader from "../Components/SectionHeader";
import { ReactComponent as SquashCourtIcon } from "../assets/Squash Court.svg";
import ClubHeader from "../Components/ClubHeader";

export default function CourtBooking() {
	return (
		<>
			<ClubHeader />
			<Box
				sx={{
					p: 5,
					pt: 8,
				}}>
				<Box>
					<Box position={"relative"}>
						<SectionHeader
							title="COURT BOOKING"
							icon={require("../assets/Squash Court.png")}
							iconStyle={{
								width: 30,
								marginRight: "auto",
								position: "absolute",
								left: 0,
							}}
							showBorder
							borderColor="#d0d0d0"
						/>
					</Box>
				</Box>
				<Box>
					<CourtBookingComponent />
				</Box>
			</Box>
			<Footer />
		</>
	);
}
