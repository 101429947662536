import { Box, Button, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";

const CourtFinderMap = () => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				width: "100%",
				height: "400px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<img
				src={require("../assets/Squash-Map 1.png")}
				style={{
					width: "100%",
					height: "500px",
					objectFit: "cover",
					position: "absolute",
					zIndex: -1,
				}}
				alt="blue map"
			/>
			<Box
				sx={{
					width: 350,
					padding: 2,
					background: "rgba(255, 255, 255, 0.75)",
					borderRadius: 10,
					margin: "auto",
					border: "1.5px solid #1F3A6E",
					textAlign: "center",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: 1,
				}}>
				<Typography
					sx={{
						fontSize: 20,
						color: "#1F3A6E",
						fontWeight: "500",
					}}>
					COURT FINDER
				</Typography>
				<Typography
					sx={{
						fontSize: 16,
						color: "#7f7f7f",
					}}>
					We provide our partners with court finding features for their website
					or app.
				</Typography>
				<Button
					onClick={() => navigate("/vu")}
					sx={{
						bgcolor: "#1782FF",
					}}>
					Search Now
				</Button>
			</Box>
		</div>
	);
};

export default CourtFinderMap;
