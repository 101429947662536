import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";
import { Chatroom, ChatroomBody } from "../types/clubhubApis.types";

export const chatroomApi = createApi({
	reducerPath: "chatroomApi",
	baseQuery,
	endpoints: (builder) => ({
		createChatroom: builder.mutation<Chatroom, ChatroomBody>({
			query: (data) => ({
				url: "/rooms",
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const { useCreateChatroomMutation } = chatroomApi;
