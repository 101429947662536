import { Box, Input, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import { NavHeader } from "./Header";
import { Link, useParams } from "react-router-dom";
import { useGetVenueQuery } from "../apis/squashFinder.api";
import {
	useGetClubQuery,
	useSaveClubDetailsMutation,
} from "../apis/clubhub.api";
import debounce from "debounce";

const styles: {
	[key: string]: React.CSSProperties;
} = {
	header: {
		width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
	main: {
		height: 325,
		width: "100%",
		backgroundColor: "#9BAFBC",
		fontSize: 20,
		display: "flex",
		flexDirection: "column",
	},
	mainTitle: {
		fontSize: 40,
		fontWeight: "700",
	},
	mainAddress: {
		fontSize: 20,
	},
};
const tabs = [
	{
		title: "Home",
		route: "admin",
	},
	{
		title: "Blog",
		route: "blog",
	},
	{
		title: "Programming",
		route: "programing",
	},
	{
		title: "Court Booking",
		route: "courtbooking",
	},

	{
		title: "Fees and Memberships",
		route: "fees",
	},
	{
		title: "Gallery",
		route: "gallery",
	},
	{
		title: "Pricing",
		route: "pricing",
	},
];

export default function AdminHeader() {
	const venueName = useParams<{ venueName: string }>().venueName;

	const [altName, setAltName] = useState("");

	const { data: venue } = useGetVenueQuery(venueName || "");
	const { data: club, refetch: refetchClub } = useGetClubQuery(venueName || "");
	const [saveClubDetails] = useSaveClubDetailsMutation();

	useEffect(() => {
		setAltName(club?.altName || "");
	}, [club?.altName]);

	const debouncedSave = useCallback(
		debounce((altName: string) => {
			const formData = new FormData();
			formData.append("altName", altName);
			saveClubDetails({
				id: club?._id || "",
				data: {
					//@ts-ignore
					altName,
				},
			});

			refetchClub();
		}, 1000),
		[club?._id, saveClubDetails]
	);

	const onAltNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAltName(e.target.value);
		debouncedSave(e.target.value);
	};

	const root = "/vu/" + venueName + "/admin";
	const currentTab = window.location.pathname.split("/").pop();

	console.log("currentTab", club?.altName);
	return (
		<div>
			<NavHeader />
			<Box style={styles.main}>
				<Box
					sx={{
						padding: {
							xs: "20px",
							sm: "20px 60px",
						},
					}}>
					<Typography style={styles.mainTitle}>{venue?.name}</Typography>
					<Input
						onChange={onAltNameChange}
						value={altName}
						placeholder="Nick name or Alternate Name (Optional)"
						sx={{ width: 350, mb: 1 }}
					/>
					<div style={styles.mainAddress}>{venue?.physical_address}</div>
				</Box>

				<Box
					sx={{
						marginTop: "auto",
						display: "flex",
						justifyContent: "space-around",

						overflowX: {
							xs: "auto",
							lg: "hidden",
						},
						gap: {
							xs: 2,
							sm: 0,
						},
						padding: {
							xs: "10px 10px",
							sm: "10px 0px",
						},

						flexWrap: "nowrap",
					}}>
					<Typography sx={{ color: "black" }}>Administration site</Typography>
					{tabs.map((tab) => (
						<Link
							to={tab.route === "admin" ? root : root + "/" + tab.route}
							style={{ textDecoration: "none" }}>
							<Typography
								sx={{ color: currentTab === tab.route ? "black" : "white" }}>
								{tab.title}
							</Typography>
						</Link>
					))}
				</Box>
			</Box>
		</div>
	);
}
