import { Box, Button, Input, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import { ClubAdmin } from "../types/clubhubApis.types";
import { FC, useState } from "react";
import clubHubApi, {
	useAddClubAdminMutation,
	useRemoveClubAdminMutation,
} from "../apis/clubhub.api";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Admin = ({
	admin,
	onDelete,
}: {
	admin: ClubAdmin;
	onDelete: (email: string) => Promise<void>;
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				gap: 3,
				alignItems: "center",
			}}>
			<Typography sx={{ width: 150 }}>{admin.firstName}</Typography>
			<Typography sx={{ width: 150 }}>{admin.lastName}</Typography>
			<Typography sx={{ width: 300 }}>{admin.email}</Typography>
			<Button
				onClick={() => onDelete(admin.email)}
				sx={{
					minHeight: 25,
				}}>
				Delete
			</Button>
		</Box>
	);
};

const AddAdmin = ({ onAddAdmin }: any) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");

	return (
		<Box
			sx={{
				display: "flex",
				gap: 3,
				alignItems: "center",
			}}>
			<Typography sx={{ width: 150 }}>
				<Input
					placeholder="First Name"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
			</Typography>
			<Typography sx={{ width: 150 }}>
				<Input
					placeholder="Last Name"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
			</Typography>
			<Typography sx={{ width: 300 }}>
				<Input
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</Typography>
			<Button
				onClick={() => onAddAdmin({ firstName, lastName, email })}
				sx={{
					minHeight: 25,
				}}>
				Add
			</Button>
		</Box>
	);
};

interface AddAdministratorsProps {
	admins: ClubAdmin[];
	clubId: string;
}

const AddAdministrators: FC<AddAdministratorsProps> = ({ admins, clubId }) => {
	const [addAdmin] = useAddClubAdminMutation();
	const [removeAdmin] = useRemoveClubAdminMutation();
	const dispatch = useDispatch();
	const venueName = useParams<{ venueName: string }>().venueName || "";

	const handleAddAdmin = async ({
		firstName,
		lastName,
		email,
	}: {
		firstName: string;
		lastName: string;
		email: string;
	}) => {
		try {
			if (!firstName || !lastName || !email) {
				alert("Please fill all fields");
				return;
			}

			const newClubData = await addAdmin({
				firstName,
				lastName,
				email,
				//@ts-ignore
				clubId,
			}).unwrap();

			// modify the query cache to add the new admin
			dispatch(
				clubHubApi.util.updateQueryData("getClub", venueName, (data: any) => {
					return newClubData;
				})
			);

			alert("Admin added successfully");
		} catch (error) {
			console.error(error);
			alert("An error occurred while adding admin");
		}
	};

	const handleDeleteAdmin = async (email: string) => {
		try {
			await removeAdmin({
				email,
				clubId,
			}).unwrap();

			// modify the query cache to remove the admin
			dispatch(
				clubHubApi.util.updateQueryData("getClub", venueName, (data: any) => {
					return {
						...data,
						admins: data.admins.filter(
							(admin: ClubAdmin) => admin.email !== email
						),
					};
				})
			);

			alert("Admin removed successfully");
		} catch (error) {
			console.error(error);
			alert("An error occurred while removing admin");
		}
	};

	return (
		<div>
			<SectionHeader title="Add Administrators" />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					py: 3,
				}}>
				{admins.map((admin, index) => (
					<Admin key={index} admin={admin} onDelete={handleDeleteAdmin} />
				))}
				<AddAdmin clubId={clubId} onAddAdmin={handleAddAdmin} />
			</Box>
		</div>
	);
};

export default AddAdministrators;
