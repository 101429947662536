import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import Club from "../assets/Club.png";
import { Tournament } from "../types/clublocker.types";

const styles = {
	container: {
		background: "#9BAFBC",
		padding: "25px 80px",
	},
	headerIcon: {
		width: 45,
	},
	contentBox: {
		display: "flex",
		justifyContent: "center",
		gap: 1,
		marginTop: 4,
		flexDirection: "column",
		alignItems: {
			xs: "none",
			md: "center",
		},
	},
	itemBox: {
		width: {
			xs: "100%",
			md: 450,
		},
		height: 40,
		backgroundColor: "#AFBFC9",
		display: "flex",
		alignItems: "center",
		pl: 2,
		mx: 1.5,
	},
	footerBox: {
		height: {
			xs: "auto",
			sm: 50,
		},
		backgroundColor: "#AFBFC9",
		display: "flex",
		alignItems: "center",
		p: "0 20px",
		mt: 1,
	},
};

const ProgrammingItem = ({ text }: { text: string }) => (
	<Box sx={styles.itemBox}>
		<Typography sx={{ color: "white", fontWeight: "bold" }}>{text}</Typography>
	</Box>
);

const Programming = ({ items }: { items: Tournament[] }) => {
	return (
		<Box sx={styles.container}>
			<SectionHeader
				title={"PROGRAMMING"}
				titleColor="white"
				borderColor="white"
			/>
			<Box sx={styles.contentBox}>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: {
							xs: "1fr",
							lg: "repeat(2, 1fr)",
						},
						gap: 0.5,
					}}>
					{items.map((item, index) => (
						<ProgrammingItem key={index} text={item.TournamentName} />
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default Programming;
