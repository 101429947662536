import { useParams } from "react-router-dom";
import ClubHeader from "../Components/ClubHeader";
import { useGetBlogPostQuery, useGetBlogPostsQuery } from "../apis/clubhub.api";
import { Box, Typography } from "@mui/joy";
import SectionHeader from "../Components/SectionHeader";
import Footer from "../Components/Footer";
import { useEffect, useMemo } from "react";
import { NewsPost } from "../Components/NewsCarousel";

const BlogPost = () => {
	const { blogId, venueName } = useParams<{
		blogId: string;
		venueName: string;
	}>();

	const { data: getBlogPostsRes, isLoading } = useGetBlogPostsQuery(
		venueName || "",
		{
			skip: !venueName,
		}
	);

	const { data: blogPost } = useGetBlogPostQuery(blogId || "", {
		skip: !blogId,
	});

	useEffect(() => {
		// scroll to top
		window.scrollTo(0, 0);
	}, []);

	const blogPosts = getBlogPostsRes?.data;
	const items = useMemo(
		() =>
			blogPosts?.map((news) => {
				const img = news.image;
				return (
					<Box
						sx={{
							mt: 2,
						}}>
						<NewsPost title={news.title} image={news.image} id={news._id} />
					</Box>
				);
			}),
		[blogPosts]
	);

	return (
		<div>
			<ClubHeader />
			<img
				src={"https://storage.googleapis.com/activscout/" + blogPost?.image}
				alt="featuredImage"
				style={{
					width: "100%",
					maxHeight: 500,
					objectFit: "cover",
				}}
			/>
			<Box
				sx={{
					p: 3,
				}}>
				<SectionHeader
					title={blogPost?.title?.toUpperCase() || ""}
					showBorder
				/>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						mt: 3,
						px: 4,
						flexDirection: {
							xs: "column",
							md: "row",
						},
					}}>
					<Box
						sx={{
							width: 300,
							height: "100%",
						}}>
						<Box width={300} />
						<Typography>Contents:</Typography>
					</Box>
					<Box
						sx={{
							flex: 1,
						}}>
						<Typography level="body-md">
							<div
								dangerouslySetInnerHTML={{
									__html: blogPost?.content as string,
								}}
							/>
						</Typography>

						<Box
							sx={{
								display: "flex",
								gap: 2,
							}}>
							{blogPost?.tags.map((tag) => (
								<Typography key={tag} level="body-md">
									{tag}
								</Typography>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							width: 300,
							height: "100%",
						}}>
						<Box width={300} />
						<Typography level="h4" sx={{ color: "#1f3a6e" }}>
							Robert Eberhard
						</Typography>
						<Typography level="body-md" sx={{ color: "#7f7f7f" }}>
							Meadow Park Recreation Centre
						</Typography>
						<Typography level="body-sm" sx={{ color: "#7f7f7f" }} mt={2}>
							I am a former teaching pro and the founding president of the
							Whistler Squash Club. My goal is to grow the sport of squash
							around the globe.
						</Typography>

						<Typography level="h3" mt={5}>
							Recent Posts
						</Typography>
						<Box>{items}</Box>
					</Box>
				</Box>
			</Box>

			<Footer />
		</div>
	);
};

export default BlogPost;
