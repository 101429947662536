import { configureStore } from "@reduxjs/toolkit";
import { clublockerApi } from "../apis/clublocker.api";
import { clubHubApi } from "../apis/clubhub.api";
import { squashFinderApi } from "../apis/squashFinder.api";
import { authApi } from "../apis/auth.api";
import { blogApi } from "../apis/blog.api";
import { clubApi } from "../apis/club.api";
import { feesApi } from "../apis/fees.api";
import { courtApi } from "../apis/court.api";
import { chatroomApi } from "../apis/chatroom.api";

const store = configureStore({
	reducer: {
		[clublockerApi.reducerPath]: clublockerApi.reducer,
		[clubHubApi.reducerPath]: clubHubApi.reducer,
		[squashFinderApi.reducerPath]: squashFinderApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[blogApi.reducerPath]: blogApi.reducer,
		[clubApi.reducerPath]: clubApi.reducer,
		[feesApi.reducerPath]: feesApi.reducer,
		[courtApi.reducerPath]: courtApi.reducer,
		[chatroomApi.reducerPath]: chatroomApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			clublockerApi.middleware,
			clubHubApi.middleware,
			squashFinderApi.middleware,
			authApi.middleware,
			blogApi.middleware,
			clubApi.middleware,
			feesApi.middleware,
			courtApi.middleware,
			chatroomApi.middleware
		),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
