import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base.api";
import { BlogPost, BlogPostsResponse } from "../types/clubhubApis.types";

export const blogApi = createApi({
	reducerPath: "blogApi",
	baseQuery,
	endpoints: (builder) => ({
		createBlogPost: builder.mutation<any, FormData>({
			query: (post) => ({
				url: "/blog-post",
				method: "POST",
				body: post,
				headers: { contentType: "multipart/form-data" },
			}),
		}),
		updateBlogPost: builder.mutation<any, { post: FormData; id: string }>({
			query: ({ post, id }) => ({
				url: `/blog-post/${id}`,
				method: "PATCH",
				body: post,
				headers: { contentType: "multipart/form-data" },
			}),
		}),
		getBlogPosts: builder.query<BlogPostsResponse, string>({
			query: (clubId) => `/blog-post?clubId=${clubId}&$sort[position]=1`,
		}),
		getBlogPost: builder.query<BlogPost, string>({
			query: (id) => `/blog-post/${id}`,
		}),
		removeBlogPost: builder.mutation<any, string>({
			query: (id) => ({
				url: `/blog-post/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const {
	useCreateBlogPostMutation,
	useUpdateBlogPostMutation,
	useGetBlogPostsQuery,
	useGetBlogPostQuery,
	useRemoveBlogPostMutation,
} = blogApi;
