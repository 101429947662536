import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import { FC, useMemo } from "react";
import { SxProps } from "@mui/joy/styles/types";

interface VenueFacilitiesProps {
	venue: any;
}

const facilitiesMapping: Record<string, string> = {
	"Hot Showers": "hot_shower",
	"Changing Rooms": "changing_room",
	Lockers: "locker",
	Cafe: "cafe",
	"Water Fountains": "water_fountain",
	"Drinks Vending Machine": "drink_vending_machine",
	"Snack Vending Machine": "snack_vending_machine",
	"Free Parking": "free_parking",
	"Online Booking": "online_booking",
	"Permanently Staffed": "staff",
	Defibrillator: "defibrillator",
	"First Aid": "first_aid_kit",
};

const styles: {
	[key: string]: SxProps;
} = {
	container: {
		background: "#1F3A6E",
		padding: {
			md: 5.5,
			sm: 2,
			xs: 4.5,
		},
		color: "white",
	},
	facilitiesGrid: {
		width: "fit-content",
		margin: "0 auto",
		marginTop: 5,
	},
	grid: {
		display: "grid",
		gridTemplateColumns: {
			xs: "1fr",
			md: "repeat(3, 1fr)",
		},
		gap: 0.5,
	},
	facilityBox: {
		width: 250,
		padding: 1,
		paddingLeft: 2,
		background: "#425882",
		mt: {
			xs: 2,
			sm: 0,
		},
		mx: 2,
	},
	facilityText: {
		color: "white",
		fontWeight: "700",
	},
};

const VenueFacilities: FC<VenueFacilitiesProps> = ({ venue }) => {
	const facilities = useMemo(() => {
		if (!venue) return [];
		return Object.keys(facilitiesMapping).filter(
			(facility) => venue[facilitiesMapping[facility]] === "Yes"
		);
	}, [venue]);

	return (
		<Box sx={styles.container}>
			<Box sx={{ position: "relative" }}>
				<SectionHeader title="VENUE FEATURES" titleColor="white" />
			</Box>
			<Box sx={styles.facilitiesGrid}>
				{facilities.length === 0 ? (
					<Typography sx={{ color: "white", textAlign: "center" }}>
						No facilities available
					</Typography>
				) : (
					<Box sx={styles.grid}>
						{facilities.map((facility, index) => (
							<Box key={index} sx={styles.facilityBox}>
								<Typography sx={styles.facilityText}>{facility}</Typography>
							</Box>
						))}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default VenueFacilities;
