import { Box, Typography } from "@mui/joy";
import { FC } from "react";
import SectionHeader from "./SectionHeader";

interface TestimonialProps {
	name: string;
	title: string;
	quote: string;
}

const Testimonial: FC<TestimonialProps> = ({ name, title, quote }) => {
	return (
		<Box
			sx={{
				width: {
					xs: "50%",
					lg: 500,
				},
				padding: 2,
				borderRadius: 5,
				margin: 2,
				textAlign: "start",
				display: "flex",
				flexDirection: "column",
			}}>
			<Typography
				sx={{
					fontFamily: "Sorts Mill Goudy",
					fontStyle: "italic",
					color: "#7f7f7f",
				}}>
				{quote}
			</Typography>
			<Box
				sx={{
					ml: "auto",
					alignSelf: "flex-end",
					display: "flex",
					alignItems: "center",
					gap: 2,
				}}>
				<img
					src={require("../assets/testimonial_avatar_sample.png")}
					style={{ width: 50, height: 50, borderRadius: "50%" }}
				/>

				<Typography
					sx={{
						fontFamily: "Sorts Mill Goudy",
						fontStyle: "italic",
						fontWeight: "500",
					}}>
					{name}
				</Typography>
			</Box>
		</Box>
	);
};

const PartnerTestimonials = () => {
	return (
		<div>
			<Box
				sx={{
					px: {
						xs: 1,
						md: 10,
					},
					py: 10,
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
				}}>
				<SectionHeader title="PARTNER TESTIMONIALS" />
				<Typography
					level="body-md"
					mt={2}
					sx={{
						color: "#7f7f7f",
						width: {
							xs: "100%",
							lg: "60%",
						},
						fontSize: 14,
					}}>
					On the surface many people believe that we are in competition with our
					partners, but in reality our partners generate their revenue almost
					exclusively from the clubs they work with. We don’t charge the clubs
					anything which is why our partnership works so well.
				</Typography>
				<Box
					sx={{
						display: "flex",
						mt: 5,
					}}>
					<Testimonial
						name="John Doe"
						title="CEO, Clubhub"
						quote="It’s just brilliant, We are now focus completely on the needs of our without being distracted. We believe that we can be the best racquet sport club management tool on the market and we can provide our services on the most competitive terms thanks to our partnership with ClubHub."
					/>
					<Testimonial
						name="John Doe"
						title="CEO, Clubhub"
						quote="It’s just brilliant, We are now focus completely on the needs of our without being distracted. We believe that we can be the best racquet sport club management tool on the market and we can provide our services on the most competitive terms thanks to our partnership with ClubHub."
					/>
				</Box>
			</Box>
		</div>
	);
};

export default PartnerTestimonials;
