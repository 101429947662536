import { Close, Search } from "@mui/icons-material";
import { Box, CircularProgress, Input } from "@mui/joy";
import { FC, useState, useCallback } from "react";
import { useGetVenuesQuery } from "../apis/squashFinder.api";
import { useMap } from "react-leaflet";

interface Prediction {
	description: string;
	place_id: string;
}

interface SearchComponentProps {
	onSelect: (value: Prediction | any) => void;
}

const SearchComponent: FC<SearchComponentProps> = ({ onSelect }) => {
	const [searchValue, setSearchValue] = useState("");
	const [venueSearchResults, setVenueSearchResults] = useState<any[]>([]);
	const [locationSearchResults, setLocationSearchResults] = useState<
		Prediction[]
	>([]);

	const map = useMap();
	const { data: venues = [] } = useGetVenuesQuery();

	const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);

		if (value.length < 3) {
			setVenueSearchResults([]);
			setLocationSearchResults([]);
			return;
		}

		const results = venues.filter((venue: any) =>
			venue.name.toLowerCase().includes(value.toLowerCase())
		);
		setVenueSearchResults(results);

		const locationResults = await searchGoogleMaps(value);
		setLocationSearchResults(locationResults);
	};

	const searchGoogleMaps = useCallback(async (input: string) => {
		try {
			const response = await fetch(
				`https://gmaps.clubhub.net/autocomplete/?input=${input}`
			);
			const data = await response.json();
			return data.predictions || [];
		} catch (error) {
			console.error("Google Maps API Error:", error);
			return [];
		}
	}, []);

	const onLocationSelect = async (prediction: Prediction) => {
		try {
			const response = await fetch(
				`https://gmaps.clubhub.net/place-details/?place_id=${prediction.place_id}`
			);
			const data = await response.json();
			const { lat, lng } = data.result.geometry.location;
			map.flyTo([lat, lng], 15);
		} catch (error) {
			console.error("Location Select Error:", error);
		}
	};

	const onVenueSelect = (venue: any) =>
		map.flyTo([+venue.latitude, +venue.longitude], 15);

	return (
		<Box sx={{ position: "absolute", top: 20, zIndex: 1000, right: 20 }}>
			<Input
				type="text"
				value={searchValue}
				onChange={handleSearch}
				sx={{ width: 300 }}
				placeholder="Search for a venue or location"
				startDecorator={<Search />}
				endDecorator={
					searchValue && (
						<Close
							onClick={() => {
								setSearchValue("");
								setVenueSearchResults([]);
								setLocationSearchResults([]);
							}}
							sx={{
								cursor: "pointer",
								fontSize: 16,
							}}
						/>
					)
				}
			/>

			{(venueSearchResults.length > 0 || locationSearchResults.length > 0) && (
				<ul
					style={{
						backgroundColor: "white",
						padding: "10px",
						borderRadius: "5px",
						width: 300,
						maxHeight: 500,
						overflowY: "auto",
						marginTop: 1,
					}}>
					{venueSearchResults.length > 0 && (
						<>
							<li style={{ fontWeight: "bold", paddingBottom: 5 }}>Venues</li>
							{venueSearchResults.map((result) => (
								<li
									key={result.id}
									onClick={() => onVenueSelect(result)}
									style={{ cursor: "pointer", paddingBottom: 5 }}>
									{result.name}
								</li>
							))}
						</>
					)}
					{locationSearchResults.length > 0 && (
						<>
							<li style={{ fontWeight: "bold", paddingBottom: 5 }}>
								Locations
							</li>
							{locationSearchResults.map((result) => (
								<li
									key={result.place_id}
									onClick={() => onLocationSelect(result)}
									style={{ cursor: "pointer", paddingBottom: 5 }}>
									{result.description}
								</li>
							))}
						</>
					)}
				</ul>
			)}
		</Box>
	);
};

export default SearchComponent;
