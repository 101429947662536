import { Box, Input, Typography } from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import { useParams } from "react-router-dom";
import CourtBookingComponent from "../../Components/CourtBookingComponent";

const CourtBookingAdmin = () => {
	const venueName = useParams<{ venueName: string }>().venueName as string;
	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: 5,
				}}>
				<Box
					sx={{
						bgcolor: "#D0d0d0",
						px: 2,
						pb: 1,
					}}>
					<Box
						sx={{
							py: 1,
							borderBottom: 1,
							borderColor: "black",
							mb: 1,
						}}>
						<Typography
							textAlign={"center"}
							level="h1"
							fontWeight={"400"}
							sx={{
								color: "#1f3a6e",
							}}>
							ATTENTION
						</Typography>
					</Box>
					<Typography>
						ClubHub works with a number of the leading court booking software
						systems. Let us know which booking system{" "}
						<Typography fontWeight={"bold"}>
							Meadow Park Sports Centre
						</Typography>{" "}
						works with. In many cases we can integrate court booking right away
						for your website. For other systems it may take us a while to
						integrate court booking for you as we will need to make the
						appropriate arrangements.
					</Typography>
					<Input
						placeholder="Enter the name of the Meadow Park Sports Centre Court Booking Software here:"
						sx={{
							mt: 2,

							borderWidth: 0,
							borderRadius: 0,
						}}
					/>
				</Box>

				<Box
					sx={{
						py: 2,
						pb: 4,
					}}>
					<Typography
						sx={{
							color: "#1f3a6e",
							fontWeight: "400",
							fontSize: 20,
						}}>
						Select option 1 or 2:
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							mt: 2,
						}}>
						<Typography>1.</Typography>
						<Typography>
							I wish to display court availability to the general public on this
							website and over the ClubHub App for Pay to Play service or for
							existing membership, provided they have signed in.
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							mt: 2,
						}}>
						<Typography>2.</Typography>
						<Typography>
							I do not wish to display court booking on this website and the
							ClubHub app to the general public unless a club member has signed
							in and confirmed they have the appropriate rights to book a court.
						</Typography>
					</Box>
				</Box>
				<CourtBookingComponent />
			</Box>
		</div>
	);
};

export default CourtBookingAdmin;
